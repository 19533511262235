import React from 'react'
import PredepCvForm from '../common/PredepCvForm'
import MoeafCoverLetter from './MoeafCoverLetter'

const PredepSw = ({gpOrMem,id}) => {
  return (
    <div>
      <PredepCvForm></PredepCvForm>
      <MoeafCoverLetter/>
    </div>
  )
}

export default PredepSw
