import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  screen: {
    pageBreakAfter: "always",
  },
});

const DlSw = () => {
  const classes = useStyles();

  return (
    <Box className={classes.screen} sx={{ p: 15 }}>
      <Typography
        sx={{
          textAlign: "end",
          fontFamily: "PdsFont",
          fontSize: "20px",
        }}
      >
        Date .28 Apr. 2023
      </Typography>
      <Box
        sx={{
          width: "70%",
          my: 2,
        }}
      >
        <Typography
          sx={{ fontFamily: "PdsFont", fontSize: "20px", lineHeight: "30px" }}
        >
          International Bussiness Consulting Association
        </Typography>
        <Typography
          sx={{ fontFamily: "PdsFont", fontSize: "20px", lineHeight: "30px" }}
        >
          No.86-1, Asanominami, Awaji-shi, Hyogo-ken, Japan
        </Typography>
        <Typography
          sx={{ fontFamily: "PdsFont", fontSize: "20px", lineHeight: "30px" }}
        >
          PHONE: 09987654321
        </Typography>
        <Typography
          sx={{ fontFamily: "PdsFont", fontSize: "20px", lineHeight: "30px" }}
        >
          FAX:(123)-456-7890
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize: "30px",
          textAlign: "center",
          fontFamily: "PdsFont",
          lineHeight: "30px",
          my: 5,
        }}
      >
        LETTER OF DEMAND FOR WORKER RECRUITMENT
      </Typography>
      <Typography
        sx={{
          fontFamily: "PdsFont",
          fontSize: "20px",
          mb: 3,
        }}
      >
        We would like to recruit Myanmar Staff for employment in our Company
        under the following conditions:
      </Typography>
      {demandList.map((demandData, index) => (
        <Grid
          key={index}
          container
          spacing={2}
          sx={{
            pb: 3,
            pageBreakAfter: index === 18 ? 'always' : '',
            pt: index === 19 ? 15 : 0,
          }}
        >
          <Grid item xs={4}>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Typography
                sx={{
                  mr: "20px",
                  fontFamily: "PdsFont",
                  fontSize: "20px",
                }}
              >
                {index + 1}.
              </Typography>
              <Typography
                sx={{
                  fontFamily: "PdsFont",
                  fontSize: "20px",
                  lineHeight: "30px",
                }}
              >
                {demandData.title}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "20px",
                lineHeight: "30px",
              }}
            >
              : {demandData.data}
            </Typography>
            <Typography sx={{ pl: 1, fontFamily: "PdsFont", fontSize: "20px" }}>
              {demandData.data2}
            </Typography>
          </Grid>
        </Grid>
      ))}
      <Box
          sx={{
            pt: 10,
          }}
        >
          <Typography
            sx={{ fontFamily: "PdsFont", fontSize: "20px", lineHeight: "30px", my: 10 }}
          >
            Sincerely,
          </Typography>
          <Typography
            sx={{ fontFamily: "PdsFont", fontSize: "20px", lineHeight: "30px" }}
          >
            Hitoshi Ito
          </Typography>
          <Typography
            sx={{ fontFamily: "PdsFont", fontSize: "20px", lineHeight: "30px" }}
          >
            Representative Director
          </Typography>
          <Typography
            sx={{ fontFamily: "PdsFont", fontSize: "20px", lineHeight: "30px" }}
          >
            One Team Co.,Ltd.
          </Typography>
          <Typography
            sx={{ fontFamily: "PdsFont", fontSize: "20px", lineHeight: "30px" }}
          >
            7-9-1 AKASAKA, MINATO CITY, TOKYO VPO AKASAKA JAPAN
          </Typography>
          <Typography
            sx={{ fontFamily: "PdsFont", fontSize: "20px", lineHeight: "30px" }}
          >
            PHONE: 03-6434-9401
          </Typography>
          <Typography
            sx={{ fontFamily: "PdsFont", fontSize: "20px", lineHeight: "30px" }}
          >
            FAX: (123)-456-7890
          </Typography>
        </Box>
    </Box>
  );
};

const demandList = [
  {
    title: "Company Name",
    data: "Green World",
  },
  {
    title: "Company Address",
    data: "Mandalay",
  },
  {
    title: "Number of Employees",
    data: "1 person",
  },
  {
    title: "Gender",
    data: "Male",
  },
  {
    title: "Age",
    data: "20-35 years old",
  },
  {
    title: "Qualification",
    data: "University Degree",
    data2: "Holder of JLPT N3/NAT-TEST N3 of Japanese Language level",
  },
  {
    title: "Health & Physical Condition",
    data: "Health certificate issued by the medical authority in Myanmar",
  },
  {
    title: "Job Category",
    data: "Engineer",
  },
  {
    title: "Workplace",
    data: "Osaka",
  },
  {
    title: "Working Hours",
    data: "7 hours per day",
  },
  {
    title: "Basic salary",
    data: "Monthly salary:JPY 200,000",
    data2:
      "Health Insurance, Social Insurance Premium, Employment Insurance Premium are as per corporate rules",
  },
  {
    title: "Salary Payment Method",
    data: "By Cash",
  },
  {
    title: "Overtime",
    data: "15 hours of overtime per month",
  },
  {
    title: "Working Days",
    data: "23 days per month days per month",
  },
  {
    title: "Holidays",
    data: "public holidays",
  },
  {
    title: "Accommodation",
    data: "Arrange by the company",
  },
  {
    title: "Transporation/Air Ticket",
    data: "The air ticket cost from Myanmar to Japan is to be borne by the company",
  },
  {
    title: "Utility Cost",
    data: "Utility cost will be paid by the employee himself/herself",
  },
  {
    title: "Training Period",
    data: "1 month",
  },
  {
    title: "Training Period Wage Scale",
    data: "Same as above",
  },
  {
    title: "Contract Period",
    data: "Till 65 years old",
  },
  {
    title: "Allowance",
    data: "Bonus, Qualification allowance",
  },
  {
    title: "Medical Insurance",
    data: "No private insurance covered Health insurance covered by the company",
  },
];

export default DlSw;
