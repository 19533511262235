import React from "react";
import { makeStyles } from '@mui/styles';
import { Box, Typography } from "@mui/material";
// import letterHead from "../../../../assets/images/letter_head.png";
// import waterMark from "../../../../assets/images/clover-rm-bg.png";

const useStyles = makeStyles({
    screen: {
        pageBreakAfter: 'always',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    dottedUnderline: {
      borderBottom: "1px dotted #000",
      display: 'inline',
    },
});

const NDCoverLetter = () => {
    const classes = useStyles();
    return (
        <div className={classes.screen}>
                <Box
                    sx={{
                        mx: "auto",
                        p: 15,
                    }}
                >
                <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px' }}>သို့</Typography>
                <Box sx={{
                    pl: 3
                }}>
                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px' }}>ညွှန်ကြားရေးမှူးချုပ် </Typography>
                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px' }}>အလုပ်သမားညွှန်ကြားရေးဦးစီးဌာန</Typography>
                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px' }}>အလုပ်သမားဝန်ကြီးဌာန</Typography>
                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px' }}>ရုံးအမှတ် ၅၁၊ အလုပ်သမားဝန်ကြီးဌာန၊ နေပြည်တော်</Typography>
                </Box>
                <Typography sx={{
                    my: '10px',
                    textAlign: 'end',
                    fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px'
                }}>ရက်စွဲ ၊ ၂၀၂၃ ခုနှစ် ၊ စက်တင်ဘာလ၊ (  ၁၅  )ရက်</Typography>
                <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px' }}>
                    အကြောင်းအရာ။&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;OWIC ပြုလုပ်ခွင့်ပြုရန် တင်ပြခြင်း။
                </Typography>
                <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px', textAlign: 'justify' }}>
                  ၁။&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;အထက်အကြောင်းအရာပါကိစ္စနှင့်ပတ်သက်၍&nbsp;
                  <p className={classes.dottedUnderline}>ရန်ကုန်&nbsp;</p>တိုင်းဒေသကြီး ၊
                  <p className={classes.dottedUnderline}>သင်္ကန်းကျွန်း&nbsp;</p>မြို့နယ် ၊
                  <p className={classes.dottedUnderline}>လေးထောင့်ကန်</p> ရပ်ကွက်၊&nbsp;
                  <p className={classes.dottedUnderline}>ကန်တော်</p>&nbsp;လမ်း၊ 
                  အမှတ် ( <p className={classes.dottedUnderline}>၅၃၅</p> ) နေ ကျွန်တော်&nbsp;
                  <p className={classes.dottedUnderline}>အောင်စည်သူလွင်</p>&nbsp;
                  နိုင်ငံသားစိစစ်ရေးကတ်အမှတ်&nbsp;
                  <p className={classes.dottedUnderline}>၁၀/သဖရ(နိုင်)146535</p>
                  ( သာမန် မြန်မာနိုင်ငံကူးလက်မှတ်အမှတ် &#123; Passport No &#125; &nbsp;
                  <p className={classes.dottedUnderline}>MG527570</p> ) သည်
                  <p className={classes.dottedUnderline}>ဂျပန်</p> နိုင်ငံ၊
                  <p className={classes.dottedUnderline}>Osaka</p>&nbsp;ဒေသရှိ&nbsp;
                  <p className={classes.dottedUnderline}>KM United Co.,Ltd</p>&nbsp;ကုမ္ပဏီတွင်&nbsp;
                  <p className={classes.dottedUnderline}>ဆောက်လုပ်ရေးဝန်ထမ်း</p>&nbsp;
                  အလုပ်ဖြင့် ယန်း
                  &nbsp;<p className={classes.dottedUnderline}>200,000</p> လစာ 
                  ( မြန်မာငွေ <p className={classes.dottedUnderline}>၂၈</p> သိန်းခန့်) ရရှိမည်ဖြစ်သော အလုပ်အကိုင်အား အလုပ်ရှင်မှ တိုက်ရိုက်ခေါ်ဆိုသော အလုပ်လျှောက်လွှာကို 
                  မိမိ၏အစီအစဉ်ဖြင့် လျှောက်ထားခြင်း၊ အင်တာဗျူးအောင်မြင်ခြင်းများကြောင့် အလုပ်သမားအဖြစ် ခန့်အပ်ရန် 
                  ရွေးချယ်ခံရပြီး <p className={classes.dottedUnderline}>2023</p> ခုနှစ် <p className={classes.dottedUnderline}>9</p> လပိုင်း <p className={classes.dottedUnderline}>22</p> ရက်နေ့ မတိုင်မီ လုပ်ငန်းခွင်သို့ ဝင်ရောက်ရမည်ဖြစ်ပါသည်။
                <br/>
                </Typography>
                <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px', textAlign: 'justify', mb: 3 }}>
                    ၂။&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;သို့ဖြစ်၍ 
                    ကျွန်တော်&nbsp;
                    <p className={classes.dottedUnderline}>အောင်စည်သူလွင်</p>&nbsp;အား  OWIC ပြုလုပ်ခွင့်ပြု၍ ဂျပန်နိုင်ငံသို့ အချိန်မီသွားရောက် ခွင့်ပြုပါရန် တင်ပြအပ်ပါသည်။
                </Typography>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px' }}>ပူးတွဲပါ အထောက်အထားများ-</Typography>
                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px', textIndent: 30 }}>(က)&nbsp; Offer Letter (or) Demand Letter  (မိတ္တူ) ၊</Typography>
                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px', textIndent: 30 }}>(ခ)&nbsp; အလုပ်ရှင် ၊ အလုပ်သမားသဘောတူစာချုပ် (EC) ၊</Typography>
                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px', textIndent: 30 }}>(ဂ)&nbsp; ကာလရှည်နေထိုင်ခွင့် (COE)၊</Typography>
                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px', textIndent: 30 }}>(ဃ)&nbsp; နိုင်ငံကူးလက်မှတ် မိတ္တူ</Typography>
                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px', textIndent: 30 }}>(င)&nbsp; ဘွဲ့လက်မှတ် (သို့မဟုတ်) ကျွမ်းကျင်မှုအသိအမှတ်ပြုလက်မှတ်၊</Typography>
                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px', textIndent: 30 }}>(စ)&nbsp; ဂျပန်စာအရည်အချင်း၊</Typography>
                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px', textIndent: 30 }}>(ဆ)&nbsp; ပြည်ပအလုပ်အကိုင် လမ်းညွှန်သင်တန်းဆင်း လက်မှတ်၊</Typography>
                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px', textIndent: 30 }}>(ဇ)&nbsp; ပြည်ဝင်ခွင့် ဗီဇာ (Visa)။</Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <Box
                        sx={{
                          width: "50%",
                          mt: 10
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            pt: 3,
                          }}
                        >
                          <Typography width="32%" sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>လက်မှတ်</Typography>
                          <Box
                            sx={{
                              borderBottom: "2px solid #000",
                              textAlign: "left",
                              flex: "1",
                            }}
                          >
                            <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}></Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            pt: 3,
                          }}
                        >
                          <Typography width="32%" sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>အမည်</Typography>
                          <Box
                            sx={{
                              borderBottom: "2px solid #000",
                              textAlign: "left",
                              flex: "1",
                            }}
                          >
                            <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}></Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            pt: 3,
                          }}
                        >
                          <Typography width="32%" sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>ဆက်သွယ်ရန်ဖုန်း၊</Typography>
                          <Box
                            sx={{
                              borderBottom: "2px solid #000",
                              textAlign: "left",
                              flex: "1",
                            }}
                          >
                            <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>09-781709517</Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            pt: 3,
                          }}
                        >
                          <Typography width="32%" sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>Email</Typography>
                          <Box
                            sx={{
                              borderBottom: "2px solid #000",
                              textAlign: "left",
                              flex: "1",
                            }}
                          >
                            <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>yonesote.ymt@gmail.com</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                </Box>
            </Box>
        </div>
    )
}

const personList = [
    {
        name: 'HTWE HLAING',
        passportNo: '1M-111111'
    },
    {
        name: 'MYA NANDAR KHAING',
        passportNo: '1M-111111'
    }
]

export default NDCoverLetter