import React from "react";
import { makeStyles } from '@mui/styles';
import { Box, Typography } from "@mui/material";
import letterHead from "../../../../assets/images/letter_head.png";
import waterMark from "../../../../assets/images/clover-rm-bg.png";

const useStyles = makeStyles({
    screen: {
        pageBreakAfter: 'always',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
});

const NDCoverLetter = () => {
    const classes = useStyles();
    return (
        <div className={classes.screen}>
            <img src={letterHead} className="letterHead"></img>
                <Box
                    sx={{
                        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url(${waterMark})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "top",
                        backgroundSize: "60%",
                        mx: "auto",
                        px: 5,
                    }}
                >
                <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px' }}>သို့</Typography>
                <Box sx={{
                    pl: '20px'
                }}>
                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px' }}>လက်ထောက်ညွှန်ကြားရေးမှူး</Typography>
                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px' }}>OWIC 1 (ပြည်ပထွက်ခွာသက်သေခံကတ်ပြားထုတ်‌ပေးရေးဌာန ၁)</Typography>
                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px' }}>ရန်ကုန်မြို့။</Typography>
                </Box>
                <Typography sx={{
                    my: '10px',
                    textAlign: 'end',
                    fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px'
                }}>ရက်စွဲ၊ ၂၀၂၃ခုနှစ် ဇန်နဝါရီလ၁၀ရက်</Typography>
                <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px' }}>
                    အကြောင်းအရာ။&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ပြည်ပအလုပ်အကိုင်သက်သေခံကတ်ပြား (Smart Card) ထုတ်ယူခွင့်ပေးပါရန် တင်ပြခြင်း။
                </Typography>
                <Typography sx={{
                    textIndent: '50px',
                    fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px', textAlign: 'justify', mb: 3
                }}>
                    အထက်ဖော်ပြပါ ပြည်ပအလုပ်အကိုင်သက်သေခံကတ်ပြား (Smart Card) ထုတ်ယူခွင့်ကိစ္စနှင့် ပတ်သက်၍ Clover Mandalay
                    Co.,Ltd ၏ ပြည်ပအလုပ်အကိုင်ရှာဖွေရေးအစီအစဉ်ဖြင့် ၂၀၂၃ခုနှစ် မေလ (၁၄) ရက်‌နေ့အရောက် ဂျပန်နိုင်ငံရှိ Jujutsu Kaisen
                    လက်အောက်ရှိ Nanami Company သို့ သွားရောက်အလုပ်လုပ်မည်ဖြစ်သောကြောင့် အောက်ဖော်ပြပါ ပုဂ္ဂိုလ်(2)ဦးအား
                    ပြည်ပအလုပ်အကိုင် သက်သေခံကတ်ပြား (Smart Card) ထုတ်ယူခွင့်ပေးပါရန် လေးစားစွာ လျှောက်ထားအပ်ပါသည်။ ဂျပန်နိုင်ငံ
                    လူဝင်မှုကြီးကြပ်ရေးအဖွဲ့မှလည်း တရားဝင်ပြည်ဝင်ခွင့် (C.O.E) နှင့် ဂျပန်သံရုံးမှ ဗီဇာလည်းရရှိထားပါသည်။
                </Typography>
                <table style={{ margin: '10px auto', width: '80%' }}>
                    <tbody>
                        <tr>
                            <td style={{ fontFamily: 'PdsFont', fontSize: '20px', padding: '20px 30px' }}>Sr</td>
                            <td style={{ fontFamily: 'PdsFont', fontSize: '20px', padding: '20px 30px' }}>Name</td>
                            <td style={{ fontFamily: 'PdsFont', fontSize: '20px', padding: '20px 30px' }}>Passport.No</td>
                        </tr>
                        {
                            personList.map((person, index) => (
                                <tr key={index}>
                                    <td style={{ fontFamily: 'PdsFont', fontSize: '20px', padding: '10px 30px' }}>{index + 1}.</td>
                                    <td style={{ fontFamily: 'PdsFont', fontSize: '20px', padding: '10px 30px' }}>{person.name}</td>
                                    <td style={{ fontFamily: 'PdsFont', fontSize: '20px', padding: '10px 30px' }}>{person.passportNo}</td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    pr: 10
                }}>
                    <Box sx={{
                        textAlign: 'center'
                    }}>
                        <Typography sx={{
                            mt: 5,
                            mb: 15,
                            fontFamily: 'PdsFont',
                            fontSize: '20px',
                            lineHeight: '40px'
                        }}>လေးစားစွာဖြင့်</Typography>
                        <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px' }}>ချစ်စုဝေ</Typography>
                        <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px' }}>မန်နေဂျင်းဒါရိုက်တာ</Typography>
                        <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px' }}>Clover Mandalay Co., Ltd</Typography>
                    </Box>
                </Box>
            </Box>
        </div>
    )
}

const personList = [
    {
        name: 'HTWE HLAING',
        passportNo: '1M-111111'
    },
    {
        name: 'MYA NANDAR KHAING',
        passportNo: '1M-111111'
    }
]

export default NDCoverLetter