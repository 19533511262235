import React from "react";
import { makeStyles } from '@mui/styles';
import { Box, Typography } from "@mui/material";
import letterHead from "../../../../assets/images/letter_head.png";

const useStyles = makeStyles({
    screen: {
        pageBreakAfter: 'always',
        // display: 'flex',
        // flexDirection: 'column',
        // alignItems: 'center',
        minHeight: '100vh',
    },
});

const NDCoverLetter = () => {
    const classes = useStyles();
    return (
        <div className={classes.screen}
            style={{
                position: "relative",
                paddingBottom: '30px'
            }}
        >
            <img src={letterHead} className="letterHead"></img>
            <Box
                sx={{
                  // backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url(${waterMark})`,
                  // backgroundRepeat: "no-repeat",
                  // backgroundPosition: "top",
                  // backgroundSize: "60%",
                  mx: "auto",
                  px: 15,
                }}
              >
                <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '35px' }}>သို့</Typography>
                <Box sx={{
                    pl: '20px'
                }}>
                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '35px' }}>တာ၀န်ခံအရာရှိ</Typography>
                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '35px' }}>သင်တန်း(ဂျပန်)</Typography>
                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '35px' }}>သင်တန်းဆင်းလက်မှတ်ထုတ်ပေးရေးဌာန</Typography>
                </Box>
                <Typography sx={{
                    my: '10px',
                    textAlign: 'end',fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '35px'

                }}>ရက်စွဲ။&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;။(၁၀)ရက် ဇန်နဝါရီလ ၂၀၂၃ခုနှစ်</Typography>
                <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '35px' }}>
                    အကြောင်းအရာ။&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;။ ပြည်ပအလုပ်အကိုင် လမ်းညွှန်သင်တန်း တက်ရောက်ခွင့်ပြုပါရန် တင်ပြခြင်း။
                </Typography>
                <Typography sx={{
                    textIndent: '50px',
                    mb: 2,
                    fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '35px'
                }}>
                    ပြည်ပအလုပ်အကိုင် လိုင်စင်ရအေဂျင်စီ Clover Mandalay Co.,Ltd မှ ဂျပန်နိုင်ငံသို့ စေလွှတ်ရန်အတွက်
                    အောက်ပါ အလုပ်သမား (2) ဦး အား ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်းသို့ တက်ရောက်ခွင့်ပြုပါရန် ပူးတွဲပါ
                    စာရွက်စာတမ်းများဖြင့် တင်ပြအပ်ပါသည်။
                </Typography>
                <table border="1" style={{ borderCollapse: 'collapse' }}>
                    <tbody>
                        <tr>
                            <td style={{ textAlign: 'center', verticalAlign: 'middle', fontFamily: 'PdsFont', fontSize: '18px', padding: "5px 10px" }}>Sr.No</td>
                            <td style={{ textAlign: 'center', verticalAlign: 'middle', fontFamily: 'PdsFont', fontSize: '18px', padding: "5px 10px" }}>Name</td>
                            <td style={{ textAlign: 'center', verticalAlign: 'middle', fontFamily: 'PdsFont', fontSize: '18px', padding: "5px 10px" }}>Male/ <br/> Female</td>
                            <td style={{ textAlign: 'center', verticalAlign: 'middle', fontFamily: 'PdsFont', fontSize: '18px', padding: "5px 10px" }}>Date of Birth</td>
                            <td style={{ textAlign: 'center', verticalAlign: 'middle', fontFamily: 'PdsFont', fontSize: '18px', padding: "5px 10px" }}>NRC.No/<br/>Passport No.</td>
                            <td style={{ textAlign: 'center', verticalAlign: 'middle', fontFamily: 'PdsFont', fontSize: '18px', padding: "5px 10px" }}>Name of Father</td>
                            <td style={{ textAlign: 'center', verticalAlign: 'middle', fontFamily: 'PdsFont', fontSize: '18px', padding: "5px 10px" }}>Address</td>
                            <td style={{ textAlign: 'center', verticalAlign: 'middle', fontFamily: 'PdsFont', fontSize: '18px', padding: "5px 10px" }}>Contact Phone</td>
                        </tr>
                        {personList.map((person, index) => (
                            <tr key={index}>
                                <td style={{ textAlign: 'center', verticalAlign: 'middle', fontFamily: 'PdsFont', fontSize: '18px', padding: "5px 10px" }}>{index + 1}</td>
                                <td style={{ textAlign: 'center', verticalAlign: 'middle', fontFamily: 'PdsFont', fontSize: '18px', padding: "5px 10px" }}>{person.name}</td>
                                <td style={{ textAlign: 'center', verticalAlign: 'middle', fontFamily: 'PdsFont', fontSize: '18px', padding: "5px 10px" }}>{person.gender}</td>
                                <td style={{ textAlign: 'center', verticalAlign: 'middle', fontFamily: 'PdsFont', fontSize: '18px', padding: "5px 10px" }}>{person.birthDate}</td>
                                <td style={{ textAlign: 'center', verticalAlign: 'middle', fontFamily: 'PdsFont', fontSize: '18px', padding: "5px 10px" }}>{person.passportNo}</td>
                                <td style={{ textAlign: 'center', verticalAlign: 'middle', fontFamily: 'PdsFont', fontSize: '18px', padding: "5px 10px" }}>{person.fatherName}</td>
                                <td style={{ textAlign: 'center', verticalAlign: 'middle', fontFamily: 'PdsFont', fontSize: '18px', padding: "5px 10px" }}>{person.address}</td>
                                <td style={{ textAlign: 'center', verticalAlign: 'middle', fontFamily: 'PdsFont', fontSize: '18px', padding: "5px 10px" }}>{person.phoneNumber}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    pr: '50px'
                }}>
                    <Box sx={{
                        textAlign: 'center'
                    }}>
                        <Typography sx={{
                            my: 15,
                            fontFamily: 'PdsFont', fontSize: '20px'
                        }}>လေးစားစွာဖြင့်</Typography>
                        <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '35px' }}>ချစ်စုဝေ</Typography>
                        <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '35px' }}>မန်နေဂျင်းဒါရိုက်တာ</Typography>
                        <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '35px' }}>Clover Mandalay Co., Ltd</Typography>
                    </Box>
                </Box>
            </Box>
        </div>
    )
}

const personList = [
    {
        name: 'HTWE HLAING',
        gender: 'Female',
        birthDate: '26/05/1998',
        passportNo: '1M-111111',
        fatherName: 'U Aung Ngwe',
        address: 'Wuntho Township, Sagaing Division',
        phoneNumber: '09943704814'
    },
    {
        name: 'MYA NANDAR KHAING',
        gender: 'Female',
        birthDate: '09/08/2004',
        passportNo: '1M-111111',
        fatherName: 'U Tun',
        address: 'Ngazun Township, Mandalay Division',
        phoneNumber: '09757900016'
    }
]

export default NDCoverLetter