// const BASE_URL = 'https://cloverofficeapp-api.clovermandalay.org/api'; 
const BASE_URL = "https://cloverofficeapp-api.rcs-mm.com/api" 


const authName = 'cmauth2023'

const setInfoToLocal = (info)=>{
    const localStorage = window.localStorage
    localStorage.setItem(authName,JSON.stringify(info));
}

const HEADERS = ()=>{
    const token = JSON.parse(localStorage.getItem(authName))?.token;
    return {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
    }
};

const token = JSON.parse(localStorage.getItem(authName))?.token;

export { BASE_URL,HEADERS,setInfoToLocal,authName, token };