// import React from "react";
// import { makeStyles } from '@mui/styles';
// import { Box, Typography } from "@mui/material";

// const useStyles = makeStyles({
//     screen: {
//         pageBreakAfter: 'always',
//     },
// });

// const ListOfWorker = () => {
//     const classes = useStyles();
//     return (
//         <div className={classes.screen}>
//             <Box sx={{
//                 textAlign: 'center'
//             }}>
//                 <Typography>ဂျပန်နိုင်ငံသို့ စေလွှတ်ခွင့်ပြုရန် (OWIC)ကတ် ပြုလုပ်ခွင့် တင်ပြသည့်အလုပ်သမားစာရင်း</Typography>
//                 <Box height='20px' />
//                 <table border='1' style={{ borderCollapse: 'collapse', width: '100%' }}>
//                     <tbody>
//                     <tr>
//                         <td rowSpan={2} style={{ textAlign: 'center', verticalAlign: 'middle', padding: '5px' }}>စဉ်</td>
//                         <td rowSpan={2} style={{ textAlign: 'center', verticalAlign: 'middle', padding: '5px' }}>မြန်မာအေဂျင်စီ အမည်</td>
//                         <td rowSpan={2} style={{ textAlign: 'center', verticalAlign: 'middle', padding: '5px' }}>ဂျပန်ကုမ္ပဏီ အမည်</td>
//                         <td rowSpan={2} style={{ textAlign: 'center', verticalAlign: 'middle', padding: '5px' }}>အလုပ်အကိုင် အမျိုးအစား</td>
//                         <td rowSpan={2} style={{ textAlign: 'center', verticalAlign: 'middle', padding: '5px' }}>စေလွှတ်မည့် အလုပ်သမား အမည်</td>
//                         <td rowSpan={2} style={{ textAlign: 'center', verticalAlign: 'middle', padding: '5px' }}>နိုင်ငံကူး လက်မှတ်</td>
//                         <td rowSpan={2} style={{ textAlign: 'center', verticalAlign: 'middle', padding: '5px' }}>သွားရောက်မည့်နိုင်ငံ</td>
//                         <td colSpan={3} style={{ textAlign: 'center', verticalAlign: 'middle', padding: '5px' }}>လုပ်သားဦးရေ</td>
//                     </tr>
//                     <tr>
//                         <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '5px' }}>ကျား</td>
//                         <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '5px', width: '20px' }}>မ</td>
//                         <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '5px' }}>ပေါင်း</td>
//                     </tr>
//                     {
//                         personList.map((person, index) => (
//                             <tr key={index}>
//                                 <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '5px' }}>{index + 1}</td>
//                                 <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '5px' }}>{person.myanmarCompany}</td>
//                                 <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '5px' }}>{person.japanCompany}</td>
//                                 <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '5px' }}>{person.jobCategory}</td>
//                                 <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '5px' }}>{person.workerName}</td>
//                                 <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '5px' }}>{person.passportNo}</td>
//                                 <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '5px' }}>{person.country}</td>
//                                 <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '5px' }}>{person.gender === 1 ? '၁' : '၀'}</td>
//                                 <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '5px' }}>{person.gender === 2 ? '၁' : '၀'}</td>
//                                 <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '5px' }}>၁</td>
//                             </tr>
//                         ))
//                     }
//                     <tr>
//                         <td colSpan={7} style={{ textAlign: 'center', verticalAlign: 'middle', padding: '5px' }}>စုစုပေါင်း</td>
//                         <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '5px' }}>၀</td>
//                         <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '5px' }}>၂</td>
//                         <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '5px' }}>၂</td>
//                     </tr>
//                     </tbody>
//                 </table>
//             </Box>
//         </div>
//     )
// }


// export default ListOfWorker


import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { convertToMyanmarNumbers } from "../../../../utils/enTomm";

const useStyles = makeStyles({
  screen: {
    pageBreakAfter: "always",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  rotatedBox: {
    width: "100%",
    transform: `rotate(-90deg) translate(0px, -300px)`,
    // overflowX: 'auto',
  },
  table: {
    borderCollapse: "collapse",
    width: "100%",
    lineHeight: "40px",
    marginTop: "15px",
  },
  tableHeaderCell: {
    textAlign: "center",
    verticalAlign: "middle",
    fontFamily: "PdsFont",
    fontSize: "22px",
    padding: "5px",
    border: "2px solid #000",
  },
  tableCell: {
    textAlign: "center",
    verticalAlign: "middle",
    fontFamily: "PdsFont",
    fontSize: "22px",
    padding: "5px",
    border: "2px solid #000",
  },
});

const ListOfWorker = () => {
  const classes = useStyles();
  return (
    <div className={classes.screen}>
      <div style={{ height: "500px", width: "100%" }}></div>
        <Box className={classes.rotatedBox}>
          <Typography
            sx={{
              textAlign: "end",
              fontFamily: "PdsFont",
              fontSize: "20px",
            }}
          >
            ပူးတွဲ - ၂
          </Typography>
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <Typography sx={{ fontFamily: "PdsFont", fontSize: '20px', mb: 3, fontSize: '24px' }}>ဂျပန်နိုင်ငံသို့ စေလွှတ်ခွင့်ပြုရန် (OWIC)ကတ် ပြုလုပ်ခွင့် တင်ပြသည့်အလုပ်သမားစာရင်း</Typography>
          </Box>
            <table border='1' style={{ borderCollapse: 'collapse', width: '100%' }}>
              <tbody>
                <tr>
                    <td rowSpan={2} className={classes.tableHeaderCell}>စဉ်</td>
                    <td rowSpan={2} className={classes.tableHeaderCell}>မြန်မာအေဂျင်စီ အမည်</td>
                    <td rowSpan={2} className={classes.tableHeaderCell}>ဂျပန်ကုမ္ပဏီ အမည်</td>
                    <td rowSpan={2} className={classes.tableHeaderCell}>အလုပ်အကိုင် အမျိုးအစား</td>
                    <td rowSpan={2} className={classes.tableHeaderCell}>စေလွှတ်မည့် အလုပ်သမား အမည်</td>
                    <td rowSpan={2} className={classes.tableHeaderCell}>နိုင်ငံကူး လက်မှတ်</td>
                    <td rowSpan={2} className={classes.tableHeaderCell}>သွားရောက်မည့်နိုင်ငံ</td>
                    <td colSpan={3} className={classes.tableHeaderCell}>လုပ်သားဦးရေ</td>
                </tr>
                <tr>
                    <td className={classes.tableCell}>ကျား</td>
                    <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '5px', width: '20px' }}>မ</td>
                    <td className={classes.tableCell}>ပေါင်း</td>
                </tr>
                {
                    personList.map((person, index) => (
                        <tr key={index}>
                            <td className={classes.tableCell}>{index + 1}</td>
                            <td className={classes.tableCell}>{person.myanmarCompany}</td>
                            <td className={classes.tableCell}>{person.japanCompany}</td>
                            <td className={classes.tableCell}>{person.jobCategory}</td>
                            <td className={classes.tableCell}>{person.workerName}</td>
                            <td className={classes.tableCell}>{person.passportNo}</td>
                            <td className={classes.tableCell}>{person.country}</td>
                            <td className={classes.tableCell}>{person.gender === 1 ? '၁' : '၀'}</td>
                            <td className={classes.tableCell}>{person.gender === 2 ? '၁' : '၀'}</td>
                            <td className={classes.tableCell}>၁</td>
                        </tr>
                    ))
                }
                <tr>
                    <td colSpan={7} className={classes.tableCell}>စုစုပေါင်း</td>
                    <td className={classes.tableCell}>၀</td>
                    <td className={classes.tableCell}>၂</td>
                    <td className={classes.tableCell}>၂</td>
                </tr>
              </tbody>
            </table>
        </Box>
      <div style={{ height: "500px", width: "100%" }}></div>
    </div>
  );
};

const personList = [
    {
        myanmarCompany: 'Clover Mandalay Co.,Ltd',
        japanCompany: 'Nanami Company',
        jobCategory: 'House Keeping',
        workerName: 'HTWE HLAING',
        passportNo: '1M-111111',
        country: 'Japan',
        gender: 2,
    },
    {
        myanmarCompany: 'Clover Mandalay Co.,Ltd',
        japanCompany: 'Nanami Company',
        jobCategory: 'House Keeping',
        workerName: 'MYA NANDAR KHAING',
        passportNo: '1M-111111',
        country: 'Japan',
        gender: 2,
    }
]

export default ListOfWorker;
