import React from "react";
import { makeStyles } from '@mui/styles';
import { Box, Typography } from "@mui/material";
import { convertToMyanmarNumbers } from "../../../../utils/enTomm";

const useStyles = makeStyles({
    screen: {
        pageBreakAfter: 'always',
        minHeight: '100vh'
    },
});

const StAndDiv = () => {
    const classes = useStyles();
    return (
        <div className={classes.screen}>
                <Box sx={{
                    textAlign: 'center',
                    p: 10
                }}>
                    <Typography sx={{
                        textAlign: 'end',
                        fontFamily: 'PdsFont',
                        fontSize: '22px',
                        lineHeight: '40px'
                    }}>ပူးတွဲ - ၁</Typography>
                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '22px', mb: 3 }}>2024 ခုနှစ်၊ ဇန်နဝါရီလအတွင်း</Typography>
                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '22px', mb: 3 }}>တိုင်းဒေသကြီးနှင့် ပြည်နယ်အလိုက် ပြည်ပအလုပ်အကိုင်သမားသက်သေခံကတ်ပြား</Typography>
                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '22px', mb: 5 }}>ထုတ်ယူသည့် အလုပ်သမားများ၏ ကိုယ်ရေးအချက်အလက် အခြေပြဇယား</Typography>
                    <table border='1' style={{ borderCollapse: 'collapse', width: '100%', height: '100%', marginTop: '30px' }}>
                        <tbody>
                            <tr>
                                <td rowSpan={2} style={{ textAlign: 'center', verticalAlign: 'middle', width: '50px', fontFamily: 'PdsFont', fontSize: '22px' }}>စဥ်</td>
                                <td rowSpan={2} style={{ textAlign: 'center', verticalAlign: 'middle', padding: '15px 0px', fontFamily: "PdsFont", fontSize: '22px' }}>တိုင်းဒေသကြီး/ပြည်နယ်</td>
                                <td colSpan={2} style={{ textAlign: 'center', verticalAlign: 'middle', padding: '15px 0px', fontFamily: "PdsFont", fontSize: '22px' }}>ပြည်ပအလုပ်အကိုင် အလုပ်သမား သက်သေခံကတ်ပြားထုတ်ယူသူဦးရေ</td>
                                <td rowSpan={2} style={{ textAlign: 'center', verticalAlign: 'middle', padding: '15px 0px', fontFamily: "PdsFont", fontSize: '22px', width: '200px' }}>စုစုပေါင်း</td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: 'center', verticalAlign: 'middle', width: '200px', fontFamily: 'PdsFont', fontSize: '22px', padding: '10px 0px' }}>ကျား</td>
                                <td style={{ textAlign: 'center', verticalAlign: 'middle', width: '200px', fontFamily: 'PdsFont', fontSize: '22px', padding: '10px 0px' }}>မ</td>
                            </tr>
                            {
                                personList.map((person, index) => (
                                    <tr key={index}>
                                        <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '15px 0px', fontFamily: "PdsFont", fontSize: '22px' }}>{convertToMyanmarNumbers(index + 1)}</td>
                                        <td style={{ textAlign: 'left', verticalAlign: 'middle', padding: '15px 10px', fontFamily: "PdsFont", fontSize: '22px' }}>{person.stateName}</td>
                                        <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '15px 0px', fontFamily: "PdsFont", fontSize: '22px' }}>{person.maleQty === 0 ? 0 : person.maleQty}</td>
                                        <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '15px 0px', fontFamily: "PdsFont", fontSize: '22px' }}>{person.femaleQty === 0 ? 0 : person.femaleQty}</td>
                                        <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '15px 0px', fontFamily: "PdsFont", fontSize: '22px' }}>{(person.maleQty + person.femaleQty) === 0 ? 0 : person.maleQty + person.femaleQty}</td>
                                    </tr>
                                ))
                            }
                            <tr>
                                <td colSpan={2} style={{ textAlign: 'center', verticalAlign: 'middle', padding: '15px 0px', fontFamily: "PdsFont", fontSize: '22px' }}>စုစုပေါင်း</td>
                                <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '15px 0px', fontFamily: "PdsFont", fontSize: '22px' }}>{personList.reduce((total, person) => total + person.maleQty, 0)}</td>
                                <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '15px 0px', fontFamily: "PdsFont", fontSize: '22px' }}>{personList.reduce((total, person) => total + person.femaleQty, 0)}</td>
                                <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '15px 0px', fontFamily: "PdsFont", fontSize: '22px' }}>{personList.reduce((total, person) => total + person.maleQty + person.femaleQty, 0)}</td>
                            </tr>
                        </tbody>
                    </table>
                </Box>
            </div>
    )
}

const personList = [
    {
        stateName: 'ကချင်',
        maleQty: 0,
        femaleQty: 0,
    },
    {
        stateName: 'ကယား',
        maleQty: 0,
        femaleQty: 0,
    },
    {
        stateName: 'ကရင်',
        maleQty: 0,
        femaleQty: 0,
    },
    {
        stateName: 'ချင်း',
        maleQty: 0,
        femaleQty: 0,
    },
    {
        stateName: 'မွန်',
        maleQty: 0,
        femaleQty: 0,
    },
    {
        stateName: 'ရခိုင်',
        maleQty: 0,
        femaleQty: 0,
    },
    {
        stateName: 'ရှမ်း',
        maleQty: 0,
        femaleQty: 0,
    },
    {
        stateName: 'ရန်ကုန်',
        maleQty: 0,
        femaleQty: 0,
    },
    {
        stateName: 'ဧရာဝတီ',
        maleQty: 1,
        femaleQty: 0,
    },
    {
        stateName: 'ပဲခူး',
        maleQty: 0,
        femaleQty: 0,
    },
    {
        stateName: 'မန္တလေး',
        maleQty: 1,
        femaleQty: 2,
    },
    {
        stateName: 'စစ်ကိုင်း',
        maleQty: 0,
        femaleQty: 0,
    },
    {
        stateName: 'မကွေး',
        maleQty: 0,
        femaleQty: 0,
    },
    {
        stateName: 'တနင်္သာရီ',
        maleQty: 1,
        femaleQty: 1,
    },
    {
        stateName: 'နေပြည်တော်',
        maleQty: 0,
        femaleQty: 0,
    },
]

export default StAndDiv