import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    screen: {
        pageBreakAfter: 'always',
    },
});

const DlEng = () => {

    const classes = useStyles();

    return (
        <Box className={classes.screen} sx={{ p: 15 }}>
            <Box sx={{
                width: '70%',
                mb: 3
            }}>
                <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', }}>Cooperative Name: International Bussiness Consulting Association</Typography>
                <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', }}>Cooperative Address: No.86-1, Asanominami, Awaji-shi, Hyogo-ken, Japan</Typography>
                <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', }}>Tel: 0799-82-3358</Typography>
                <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', }}>Fax: 0799-82-3359</Typography>
            </Box>
            <Box sx={{
                width: '70%'
            }}>
                <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', }}>Ms. Chit Su Wai</Typography>
                <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', }}>Managing Director</Typography>
                <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', }}>Clover Mandalay Co.,Ltd.</Typography>
                <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', }}>No.24, Kant Kaw Street, Between 22ndx23rd, 88thx89th Street, Aung Myay Thar Zan Tsp, Mandalay, Myanmar.</Typography>
            </Box>
            <Typography sx={{
                my: 3,
                textAlign: 'end',
                fontFamily: 'PdsFont',
                fontSize: '20px'
            }}>Date .28 Apr. 2023</Typography>
            <Typography sx={{
                fontSize: '30px',
                textAlign: 'center',
                fontFamily: 'PdsFont',
            }}>LETTER OF DEMAND FOR WORKER RECRUITMENT</Typography>
            <Typography sx={{
                my: 3,
                fontFamily: 'PdsFont',
                fontSize: '20px'
            }}>Dear Ms. Chit Su Wai,</Typography>
            <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', }}>This is to confirm that we hereby appoint and authorize your agency to recruit Myanmar workers for employment in our Company. You are also to have the relevant authority's approval regarding the recruitment on behalf.</Typography>
            <Typography sx={{
                my: 3,
                fontFamily: 'PdsFont',
                fontSize: '20px'
            }}>Our recruitment and terms of employment are as follows:</Typography>
            {
                demandList.map((demandData, index) => (
                    <Grid key={index} container spacing={2} sx={{
                        pb: 3,
                        pageBreakAfter: index === 14 ? 'always' : '',
                        pt: index === 15 ? 15 : 0,
                    }}>
                        <Grid item xs={4}>
                            <Box sx={{
                                display: 'flex'
                            }}>
                                <Typography sx={{
                                    mr: 2
                                }}>{index+1}.</Typography>
                                <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', }}>{demandData.title}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', }}>: {demandData.data}</Typography>
                            <Typography sx={{ pl: 1, fontFamily: 'PdsFont', fontSize: '20px', }}>{demandData.data2}</Typography>
                        </Grid>
                    </Grid>
                ))
            }
            <Box sx={{
                textAlign: 'end',
                pt: 10,
            }}>
                <Typography sx={{ mb: 3, fontFamily: 'PdsFont', fontSize: '20px' }}>Yours Sincerely,</Typography>

                <Typography sx={{ mb: 3, fontFamily: 'PdsFont', fontSize: '20px' }}>Signature with seal</Typography>

                <Typography sx={{ mb: 3, fontFamily: 'PdsFont', fontSize: '20px' }}>模ま著横クトミ計遊アエ山額はぞりう</Typography>

                <Typography sx={{ mb: 3, fontFamily: 'PdsFont', fontSize: '20px' }}>International Bussiness Consulting Association (IBCA)</Typography>

                <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', }}>CEO 必ぎト王 Mitani Nobumasa</Typography>
            </Box>
        </Box>
    )
}

const demandList = [
    {
        title: 'Company name',
        data: 'YABASHI DAIRISEKI Co.,Ltd'
    },
    {
        title: 'Company address',
        data: 'No.273 Akasaka-cho, Oogaki-shi, Gifu'
    },
    {
        title: 'Number of recruitment',
        data: '4 persons'
    },
    {
        title: 'Gender',
        data: 'Male'
    },
    {
        title: 'Age',
        data: 'from 19 to 25 years old'
    },
    {
        title: 'Qualification',
        data: 'N5 Japanese Level'
    },
    {
        title: 'Health & Must be fit Physically',
        data: 'Must pass the medical check in Myanmar'
    },
    {
        title: 'Job category',
        data: 'Stone processing, stonework'
    },
    {
        title: 'Workplace',
        data: 'No.1-16-1, Nishikinya, Hirakata-Ku, Osaka, and each worksite'
    },
    {
        title: 'Working hours',
        data: '8 hours/day'
    },
    {
        title: 'Basic Salary',
        data: '165,726-yen Hourly fees 1,023 Yen',
        data2: '(1,023Yen X 8hours X 20.25day)'
    },
    {
        title: 'Salary Payment',
        data: 'Bank Transfer'
    },
    {
        title: 'Overtime',
        data: 'Additional 25% of Basic Wage-Hour'
    },
    {
        title: 'Working days',
        data: '243 days per year'
    },
    {
        title: 'Holidays',
        data: 'Every Sunday, Public holidays, Company designation holiday'
    },
    {
        title: 'Accomodation',
        data: 'Arranged by the company',
        data2: 'Monthly payment of 2,000 Yen for each Trainee'
    },
    {
        title: 'Transportation/Air Ticket',
        data: 'Borne by employer'
    },
    {
        title: 'Food',
        data: 'Foods are arranged by Training worker'
    },
    {
        title: 'Training period',
        data: '1 Month'
    },
    {
        title: 'Training period Wage scale',
        data: '70,000 Yen'
    },
    {
        title: 'Contract period',
        data: '3 Years'
    },
    {
        title: 'Medical insurance',
        data: 'JITCO apprentice comprehensive insurance'
    },
]

export default DlEng