import React from 'react'
import SmartCardForm from '../common/SmartCardForm'
import SmartCardForm2 from '../common/SmartCardForm2'
import MolCoverLetter from './MolCoverLetter'
import NDCoverLetter from './NDCoverLetter'
import StAndDiv from './StAndDiv'
import EmployeeInfoTable from './EmployeeInfoTable'
import ListOfWorker from './ListOfWorker'


const SmartCardTitssw = ({gpOrMem,id}) => {
  return (
    <div>
      <MolCoverLetter></MolCoverLetter>
      <ListOfWorker></ListOfWorker>
      <NDCoverLetter></NDCoverLetter>
      <StAndDiv></StAndDiv>
      <EmployeeInfoTable></EmployeeInfoTable>
      <SmartCardForm></SmartCardForm>
      <SmartCardForm2></SmartCardForm2>
    </div>
  )
}

export default SmartCardTitssw
