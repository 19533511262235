import React from 'react'
import CoverLetter from '../common/CoverLetter'
import EmployerContract from '../common/EmployerContract'
import AgencyContract from '../common/AgencyContract'
import AgencyList from '../common/AgencyList'
import DlEng from './DlEng'
import DlMm from './DlMm'

const DlSsw = ({gpOrMem,id}) => {
  return (
    <>
      <CoverLetter type={'ssw'}></CoverLetter>
      <EmployerContract type={'ssw'}></EmployerContract>
      <AgencyContract type={'ssw'}></AgencyContract>
      <AgencyList type={'ssw'}></AgencyList>
      <DlEng></DlEng>
      <DlMm></DlMm>
    </>
  )
}

export default DlSsw
