import { Box, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import letterHead from "../../../../assets/images/letter_head.png";
import waterMark from "../../../../assets/images/clover-rm-bg.png";

const useStyles = makeStyles({
  screen: {
    pageBreakAfter: "always",
  },
});


const DemandTITCoverLetter = ({ type }) => {
  const classes = useStyles();
  return (
    <div className={classes.screen}>
      <img src={letterHead} className="letterHead"></img>
      <Box
        sx={{
          backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url(${waterMark})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top",
          backgroundSize: "60%",
          mx: "auto",
          px: 5,
        }}
      >
        <Typography sx={{ mb: 2, fontFamily: "PdsFont", fontSize: '22px', textAlign: 'justify', wordSpacing: '5px' , lineHeight: '40px' }}>သို့</Typography>
        <Box
          sx={{
            pl: 5,
          }}
        >
          <Typography sx={{ mb: 2, fontFamily: "PdsFont", fontSize: '22px', textAlign: 'justify', wordSpacing: '5px' , lineHeight: '40px' }}>
            ညွှန်ကြားရေးမှူးချုပ်
          </Typography>
          <Typography sx={{ mb: 2, fontFamily: "PdsFont", fontSize: '22px', textAlign: 'justify', wordSpacing: '5px' , lineHeight: '40px' }}>
            အလုပ်သမားညွှန်ကြားရေးဦးစီးဌာန
          </Typography>
          <Typography sx={{ mb: 2, fontFamily: "PdsFont", fontSize: '22px', textAlign: 'justify', wordSpacing: '5px' , lineHeight: '40px' }}>
            အလုပ်သမားဝန်ကြီးဌာန
          </Typography>
        </Box>
        <Typography
          sx={{
            mb: 3,
            fontFamily: "PdsFont", fontSize: '22px', textAlign: 'right', wordSpacing: '5px' , lineHeight: '40px',
          }}
        >
          ရက်စွဲ၊ ၂၀၂၃ခုနှစ် ဇန်နဝါရီလ၁၀ရက်
        </Typography>
        <Typography sx={{ fontFamily: "PdsFont", fontSize: '22px', textAlign: 'justify', wordSpacing: '5px' , lineHeight: '40px' }}>
          အကြောင်းအရာ။&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;။{ type === 'tit' ?"အလုပ်သင်သင်တန်းသား" : "အထူးကျွမ်းကျင်လုပ်သား"}ခေါ်ယူသည့် Demand Letter
          ​ပေးပို့လျှောက်ထားခြင်း
        </Typography>
        <Typography
          sx={{
            textIndent: "50px",
            fontFamily: "PdsFont", fontSize: '22px', textAlign: 'justify', wordSpacing: '5px' , lineHeight: '40px',
          }}
        >
          အထက်ပါအကြောင်းအရာနှင့်ပတ်သက်၍ ဂျပန်နိုင်ငံ Tokyoတွင် တည်ရှိသော Nanami
          Company လုပ်ငန်းမှ { type === 'tit' ?"အလုပ်သင်သင်တန်းသား" : "အထူးကျွမ်းကျင်လုပ်သား"} (၂) ဦး ခေါ်ယူရန်
          ကျွန်တော်/ကျွန်မတို့၏ Clover Mandalay Co., Ltd (ကလိုဗာမန္တလေး
          ကုမ္ပဏီလီမိတက်) သို့ ကမ်းလှမ်းလာပါသည်။
        </Typography>
        <Typography
          sx={{
            textIndent: "50px",
            fontFamily: "PdsFont", fontSize: '22px', textAlign: 'justify', wordSpacing: '5px' , lineHeight: '40px',
          }}
        >
          စေလွှတ်မည့်အလုပ်သင်သင်တန်းသားနှင့်ပတ်သက်၍ ဂျပန်နိုင်ငံဥပဒေအရ
          လစာတိုးမြှင့်ခံစားခွင့် ရှိပါက တိုးမြှင့်ခံစားခွင့်ရရှိရေးအတွက်
          တာဝန်ယူကြောင်း၊ စေလွှတ်လိုက်သည့်အလုပ်သင်သင်တန်းသားများ မြန်မာနိုင်ငံမှ
          ထွက်ခွာချိန်မှ ပြန်လည်ရောက်ရှိချိန်အထိ ဖြစ်သမျှကိစ္စအဝဝကို
          တာဝန်ယူကြောင်းနှင့် ပျက်ကွက်ပါက လိုင်စင်ပိတ်သိမ်းသည်အထိ
          အရေးယူခြင်းခံရမည်ကို သိရှိပါကြောင်း ဝန်ခံကတိပြုပါသည်။
        </Typography>
        <Typography
          sx={{
            textIndent: "50px",
            fontFamily: "PdsFont", fontSize: '22px', textAlign: 'justify', wordSpacing: '5px' , lineHeight: '40px'
          }}
        >
          သို့ပါ၍ အဆိုပါ ကမ်းလှမ်းစာအား ခွင့်ပြုပေးနိုင်ပါရန် ပူးတွဲပါ Demand
          Letter များနှင့် ပေးပို့ လျှောက်ထားအပ်ပါသည်။
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <Box
            sx={{
              textAlign: "center",
              mr: 10,
            }}
          >
            <Typography
              sx={{
                mt: 10,
                mb: 15,
                fontFamily: "PdsFont", fontSize: '22px', wordSpacing: '5px' , lineHeight: '40px'
              }}
            >
              လေးစားစွာဖြင့်
            </Typography>
            <Typography sx={{ fontFamily: "PdsFont", fontSize: '22px', wordSpacing: '5px' , lineHeight: '40px' }}>ချစ်စုဝေ</Typography>
            <Typography sx={{ fontFamily: "PdsFont", fontSize: '22px', wordSpacing: '5px' , lineHeight: '40px' }}>မန်နေဂျင်းဒါရိုက်တာ</Typography>
            <Typography sx={{ fontFamily: "PdsFont", fontSize: '22px', wordSpacing: '5px' , lineHeight: '40px' }}>Clover Mandalay Co., Ltd</Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default DemandTITCoverLetter;
