import React from 'react'
import CoverLetter from '../common/CoverLetter'
import EmployerContract from '../common/EmployerContract'
import AgencyContract from '../common/AgencyContract'
import AgencyList from '../common/AgencyList'
import DlEng from './DlEng'
import DlMm from './DlMm'


// const getPageMargins = () => {
//   return `@page { margin: ${"10mm"} ${"0mm"} ${"10mm"} ${"0mm"} !important; }`;
// };

const DlTit = ({gpOrMem,id}) => {
  return (
    <div>
      <CoverLetter type={'tit'}></CoverLetter>
      {/* <style>{getPageMargins()}</style> */}
      <EmployerContract type={'tit'}></EmployerContract>
      <AgencyContract type={'tit'}></AgencyContract>
      <AgencyList type={'tit'}></AgencyList>
      {/* <div style={{ height: '00px', width: '100%' }}></div> */}
      <DlEng></DlEng>
      <DlMm></DlMm>
    </div>
  )
}

export default DlTit
