import { Box, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';

const SmartCardForm2 = () => {
  const useStyles = makeStyles({
    screen: {
        pageBreakAfter: 'always',
        minHeight: '100vh'
    },
  })

  const classes = useStyles()
  return (
    <Box className={classes.screen} sx={{ p: 15 }}>
      <Box
        sx={{
          display: "flex",
          width: "100%",
        }}
      >
        <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', mr: 1 }}>၁၄။</Typography>
        <Typography
          sx={{
            fontFamily: "PdsFont",
            fontSize: '20px',
            width: "35%",
          }}
        >
          ပြည်ပအေဂျင်စီအမည်
        </Typography>
        <Box
          sx={{
            borderBottom: "2px solid #000",
            display: "flex",
            width: "65%",
          }}
        >
          <Typography
            sx={{
              fontFamily: "PdsFont",
              fontSize: '20px',
              mx: "10px",
            }}
          >
            -
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          py: "15px",
        }}
      >
        <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', mr: 1 }}>၁၅။</Typography>
        <Typography
          sx={{
            fontFamily: "PdsFont",
            fontSize: '20px',
            width: "35%",
          }}
        >
          ပြည်တွင်းအေဂျင်စီအမည်
        </Typography>
        <Box
          sx={{
            borderBottom: "2px solid #000",
            display: "flex",
            width: "65%",
          }}
        >
          <Typography
            sx={{
              fontFamily: "PdsFont",
              fontSize: '20px',
              mx: "10px",
            }}
          >
            -
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          py: "15px",
        }}
      >
        <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', mr: 1 }}>၁၆။</Typography>
        <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}> မြန်မာနိုင်ငံရှိ အမြဲတမ်းနေရပ်လိပ်စာ</Typography>
        <Box width="20px" />
        <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>အမှတ်</Typography>
        <Box
          sx={{
            borderBottom: "2px solid #000",
            px: "10px",
            textAlign: "center",
          }}
        >
          <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>(3/66)</Typography>
        </Box>
        <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>လမ်း</Typography>
        <Box
          sx={{
            borderBottom: "2px solid #000",
            px: "10px",
            flex: 1,
          }}
        >
          <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>ပတ္တမြားလမ်း</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          py: "15px",
        }}
      >
        <Box width="30px" />
        <Typography
          sx={{
            fontFamily: "PdsFont",
            fontSize: '20px',
            width: "35%",
          }}
        >
          ရပ်ကွက်/
          <span style={{ textDecoration: "line-through", fontFamily: 'PdsFont', fontSize: '20px' }}>
            ကျေးရွာ/ကျေးရွာအုပ်စု
          </span>
        </Typography>
        <Box
          sx={{
            borderBottom: "2px solid #000",
            display: "flex",
            width: "65%",
          }}
        >
          <Typography
            sx={{
              fontFamily: "PdsFont",
              fontSize: '20px',
              mx: "10px",
            }}
          >
            ရပ်ကွက်ကြီး ၂၊ ဥသာမြို့သစ်
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          py: "15px",
        }}
      >
        <Box width="30px" />
        <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>မြို့နယ်</Typography>
        <Box
          sx={{
            borderBottom: "2px solid #000",
            px: "10px",
            width: "120px",
          }}
        >
          <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>ပဲခူးမြို့</Typography>
        </Box>
        <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>ခရိုင်</Typography>
        <Box
          sx={{
            borderBottom: "2px solid #000",
            px: "10px",
            width: "80px",
            textAlign: "center",
          }}
        >
          <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>-</Typography>
        </Box>
        <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>
          တိုင်းဒေသကြီး/{" "}
          <span style={{ textDecoration: "line-through" }}>ပြည်နယ်</span>
        </Typography>
        <Box
          sx={{
            borderBottom: "2px solid #000",
            px: "10px",
            flex: 1,
          }}
        >
          <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>ပဲခူး</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          py: "15px",
        }}
      >
        <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', mr: 1 }}>၁၇။</Typography>
        <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>အမွေစား/အမွေခံ အမည်</Typography>
        <Box
          sx={{
            borderBottom: "2px solid #000",
            px: "10px",
            width: "200px",
            textAlign: "center",
          }}
        >
          <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>မထက်အိရွှေရည်</Typography>
        </Box>
        <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>တော်စပ်ပုံ</Typography>
        <Box
          sx={{
            borderBottom: "2px solid #000",
            px: "10px",
            flex: 1,
            textAlign: "center",
          }}
        >
          <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>ညီမ</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          py: "15px",
        }}
      >
        <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', mr: 1 }}>၁၈။</Typography>
        <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>နေရပ်လိပ်စာ</Typography>
        <Box width="30px" />
        <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>အမှတ်</Typography>
        <Box
          sx={{
            borderBottom: "2px solid #000",
            px: "20px",
            textAlign: "center",
          }}
        >
          <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>(3/66)</Typography>
        </Box>
        <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>လမ်း</Typography>
        <Box
          sx={{
            borderBottom: "2px solid #000",
            px: "20px",
            flex: 1,
          }}
        >
          <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>ပတ္တမြားလမ်း</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          py: "15px",
        }}
      >
        <Box width="30px" />
        <Typography
          sx={{
            fontFamily: "PdsFont",
            fontSize: '20px',
            width: "35%",
          }}
        >
          ရပ်ကွက်/
          <span style={{ textDecoration: "line-through" }}>
            ကျေးရွာ/ကျေးရွာအုပ်စု
          </span>
        </Typography>
        <Box
          sx={{
            borderBottom: "2px solid #000",
            display: "flex",
            width: "65%",
          }}
        >
          <Typography
            sx={{
              fontFamily: "PdsFont",
              fontSize: '20px',
              mx: "10px",
            }}
          >
            ရပ်ကွက်ကြီး ၂၊ ဥသာမြို့သစ်
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          py: "15px",
        }}
      >
        <Box width="30px" />
        <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>မြို့နယ်</Typography>
        <Box
          sx={{
            borderBottom: "2px solid #000",
            px: "10px",
            width: "120px",
          }}
        >
          <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>ပဲခူးမြို့</Typography>
        </Box>
        <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>ခရိုင်</Typography>
        <Box
          sx={{
            borderBottom: "2px solid #000",
            px: "10px",
            width: "80px",
            textAlign: "center",
          }}
        >
          <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>-</Typography>
        </Box>
        <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>
          တိုင်းဒေသကြီး/{" "}
          <span style={{ textDecoration: "line-through" }}>ပြည်နယ်</span>
        </Typography>
        <Box
          sx={{
            borderBottom: "2px solid #000",
            px: "10px",
            flex: 1,
          }}
        >
          <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>ပဲခူး</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          py: "15px",
        }}
      >
        <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', mr: 1 }}>၁၈။</Typography>
        <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>
          အမွေခံမိဘ/ ဇနီး/ ခင်ပွန်း၏ နိုင်ငံသားမှတ်ပုံတင်အမှတ်
        </Typography>
        <Box
          sx={{
            borderBottom: "2px solid #000",
            px: "20px",
            flex: 1,
          }}
        >
          <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>၉/ရမသ(နိုင်) ၂၀၀၀၃၅</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          py: "15px",
        }}
      >
        <Box width="30px" />
        <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>ဆက်သွယ်ရမည့်ဖုန်းနံပါတ်</Typography>
        <Box width="30px" />
        <Box
          sx={{
            borderBottom: "2px solid #000",
            px: "20px",
            flex: 1,
          }}
        >
          <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>09-789251721</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          py: "15px",
        }}
      >
        <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', mr: 1 }}>၂၀။</Typography>
        <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>ခင်ပွန်း/ ဇနီးဖြစ်သူ၏ သားသမီးများရှိပါက</Typography>
      </Box>
      <Box
        sx={{
          pl: "30px",
        }}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>(က)</Typography>
          <Typography
            sx={{
              fontFamily: "PdsFont",
              fontSize: '20px',
              px: "10px",
            }}
          >
            အမည်
          </Typography>
          <Box
            sx={{
              borderBottom: "2px solid #000",
              width: "100px",
              textAlign: "center",
            }}
          >
            <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>-</Typography>
          </Box>
          <Typography
            sx={{
              fontFamily: "PdsFont",
              fontSize: '20px',
              px: "10px",
            }}
          >
            အသက်
          </Typography>
          <Box
            sx={{
              borderBottom: "2px solid #000",
              width: "50px",
              textAlign: "center",
            }}
          >
            <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>-</Typography>
          </Box>
          <Typography
            sx={{
              fontFamily: "PdsFont",
              fontSize: '20px',
              px: "10px",
            }}
          >
            နိုင်ငံသားမှတ်ပုံတင်အမှတ်
          </Typography>
          <Box
            sx={{
              borderBottom: "2px solid #000",
              textAlign: "center",
              flex: "1",
            }}
          >
            <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>-</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            py: "20px",
          }}
        >
          <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>(ခ)</Typography>
          <Typography
            sx={{
              fontFamily: "PdsFont",
              fontSize: '20px',
              px: "10px",
            }}
          >
            အမည်
          </Typography>
          <Box
            sx={{
              borderBottom: "2px solid #000",
              width: "100px",
              textAlign: "center",
            }}
          >
            <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>-</Typography>
          </Box>
          <Typography
            sx={{
              fontFamily: "PdsFont",
              fontSize: '20px',
              px: "10px",
            }}
          >
            အသက်
          </Typography>
          <Box
            sx={{
              borderBottom: "2px solid #000",
              width: "50px",
              textAlign: "center",
            }}
          >
            <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>-</Typography>
          </Box>
          <Typography
            sx={{
              fontFamily: "PdsFont",
              fontSize: '20px',
              px: "10px",
            }}
          >
            နိုင်ငံသားမှတ်ပုံတင်အမှတ်
          </Typography>
          <Box
            sx={{
              borderBottom: "2px solid #000",
              textAlign: "center",
              flex: "1",
            }}
          >
            <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>-</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>(ဂ)</Typography>
          <Typography
            sx={{
              fontFamily: "PdsFont",
              fontSize: '20px',
              px: "10px",
            }}
          >
            အမည်
          </Typography>
          <Box
            sx={{
              borderBottom: "2px solid #000",
              width: "100px",
              textAlign: "center",
            }}
          >
            <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>-</Typography>
          </Box>
          <Typography
            sx={{
              fontFamily: "PdsFont",
              fontSize: '20px',
              px: "10px",
            }}
          >
            အသက်
          </Typography>
          <Box
            sx={{
              borderBottom: "2px solid #000",
              width: "50px",
              textAlign: "center",
            }}
          >
            <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>-</Typography>
          </Box>
          <Typography
            sx={{
              fontFamily: "PdsFont",
              fontSize: '20px',
              px: "10px",
            }}
          >
            နိုင်ငံသားမှတ်ပုံတင်အမှတ်
          </Typography>
          <Box
            sx={{
              borderBottom: "2px solid #000",
              textAlign: "center",
              flex: "1",
            }}
          >
            <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>-</Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Box
          sx={{
            width: "40%",
            mt: 20
          }}
        >
          <Typography
            sx={{
              fontFamily: "PdsFont",
              fontSize: '20px',
              textAlign: "center",
            }}
          >
            (လျှောက်ထားသူလက်မှတ်)
          </Typography>
          <Box
            sx={{
              display: "flex",
              pt: 3,
            }}
          >
            <Typography width="30%" sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>အမည်</Typography>
            <Box
              sx={{
                borderBottom: "2px solid #000",
                textAlign: "left",
                flex: "1",
              }}
            >
              <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>အေးချမ်းမို့မို့</Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              pt: 3,
            }}
          >
            <Typography width="30%" sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>ရက်စွဲ</Typography>
            <Box
              sx={{
                borderBottom: "2px solid #000",
                textAlign: "left",
                flex: "1",
              }}
            >
              <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}></Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              pt: 3,
            }}
          >
            <Typography width="30%" sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>ဖုန်းနံပါတ်</Typography>
            <Box
              sx={{
                borderBottom: "2px solid #000",
                textAlign: "left",
                flex: "1",
              }}
            >
              <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>09-781709517</Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              pt: 3,
            }}
          >
            <Typography width="30%" sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>Email</Typography>
            <Box
              sx={{
                borderBottom: "2px solid #000",
                textAlign: "left",
                flex: "1",
              }}
            >
              <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>yonesote.ymt@gmail.com</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SmartCardForm2