import React from "react";
import { makeStyles } from '@mui/styles';
import { Box, Typography } from "@mui/material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import JobCat from "./JobCat";

const useStyles = makeStyles({
    screen: {
        pageBreakAfter: 'always',
        position: 'relative',
        minHeight: '100vh'
    },
    redBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        position: 'absolute',
        top: "140px",
        right: "120px"
    },
    firstInnerBox: {
        width: 180,
        height: 100,
        borderRadius: '10px',
        border: '1px solid black',
        marginBottom: '20px'
    },
    secondInnerBox: {
        width: 130,
        height: 150,
        border: '1px solid black',
        marginRight: 10
    },
    tableCell: {
        fontFamily: 'PdsFont',
        fontSize: '20px',
        padding: '20px 0px'
    },
});

const SmartCardForm = () => {
    const classes = useStyles();
    return (
        <Box className={classes.screen} sx={{ p: 15 }}>
            <Box className={classes.redBox}>
                <Box className={classes.firstInnerBox} />
                <Box className={classes.secondInnerBox} />
            </Box>
            <Box sx={{ float: 'left', width: '100%' }}>
                <Typography sx={{ fontFamily: 'PdsFontNumber', textDecoration: 'underline', fontWeight: 'bold', textAlign: 'center', mb: 3 }} variant='h5'>Overseas Worker Identification Card</Typography>
                <Typography sx={{ fontFamily: 'PdsFontNumber', textDecoration: 'underline', fontWeight: 'bold', textAlign: 'center' }} variant='h5'>ကိုယ်ရေးအချက်လတ်ပုံစံ</Typography>
                <table style={{ width: '90%', marginTop: '30px' }}>
                    <tbody>
                        <tr>
                            <td className={classes.tableCell}>၁။</td>
                            <td className={classes.tableCell}>အမည်(မြန်မာ)/အင်္ဂလိပ်</td>
                            <td className={classes.tableCell}>
                                ခင်ငြိမ်းချမ်းထက် / KHIN NYEIN CHAN HTET
                                <hr style={{ border: '1px solid #000' }}></hr>
                            </td>
                            <td style={{ minWidth: '150px' }}></td>
                        </tr>
                        <tr>
                            <td className={classes.tableCell}>၂။</td>
                            <td className={classes.tableCell}>အခြားအမည်(မြန်မာ)/အင်္ဂလိပ်</td>
                            <td className={classes.tableCell}>
                                -
                                <hr style={{ border: '1px solid #000' }}></hr>
                            </td>
                            <td style={{ minWidth: '150px' }}></td>
                        </tr>
                        <tr>
                            <td className={classes.tableCell}>၃။</td>
                            <td className={classes.tableCell}>အဘအမည်</td>
                            <td className={classes.tableCell}>
                                ဦးသောင်းတင်
                                <hr style={{ border: '1px solid #000' }}></hr>
                            </td>
                            <td style={{ minWidth: '150px' }}></td>
                        </tr>
                        <tr>
                            <td className={classes.tableCell}>၄။</td>
                            <td className={classes.tableCell}>အမိအမည်</td>
                            <td className={classes.tableCell}>
                                ဦးသောင်းတင်
                                <hr style={{ border: '1px solid #000' }}></hr>
                            </td>
                            <td style={{ minWidth: '150px' }}></td>
                        </tr>
                        <tr>
                            <td className={classes.tableCell}>၅။</td>
                            <td className={classes.tableCell}>မွေးနေ့</td>
                            <td className={classes.tableCell}>
                                ၁၇ နေ့ ၅လ ၁၉၉၂ ခုနှစ် အသက် (၃၂)
                                <hr style={{ border: '1px solid #000' }}></hr>
                            </td>
                            <td style={{ minWidth: '150px' }}></td>
                        </tr>
                        <tr>
                            <td className={classes.tableCell}>၆။</td>
                            <td className={classes.tableCell}>မွေးဖွားရာဇာတိ</td>
                            <td className={classes.tableCell}>
                                ပဲခူးမြို့
                                <hr style={{ border: '1px solid #000' }}></hr>
                            </td>
                            <td style={{ minWidth: '150px' }}></td>
                        </tr>
                        <tr>
                            <td className={classes.tableCell}>၇။</td>
                            <td className={classes.tableCell}>လူမျိုး/ ကိုးကွယ်သည့်ဘာသာ</td>
                            <td className={classes.tableCell}>
                                ဗမာ/ဗုဒ္ဓ
                                <hr style={{ border: '1px solid #000' }}></hr>
                            </td>
                            <td style={{ minWidth: '150px' }}></td>
                        </tr>
                        <tr>
                            <td className={classes.tableCell}>၈။</td>
                            <td className={classes.tableCell}>မြင်သာထင်ရှားသည့်အမှတ်အသား</td>
                            <td className={classes.tableCell}>
                                နဖူးမှည့်ရှိ
                                <hr style={{ border: '1px solid #000' }}></hr>
                            </td>
                            <td style={{ minWidth: '150px' }}></td>
                        </tr>
                        <tr>
                            <td className={classes.tableCell}></td>
                            <td className={classes.tableCell}></td>
                            <td className={classes.tableCell}>
                               <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <span className={classes.tableCell} style={{ marginRight: '10px' }}>ကျား</span> <CheckBoxOutlineBlankIcon></CheckBoxOutlineBlankIcon>
                                <span className={classes.tableCell} style={{ marginLeft: "120px", marginRight: '10px' }}>မ</span> <CheckBoxOutlineBlankIcon></CheckBoxOutlineBlankIcon>
                               </Box>
                            </td>
                        </tr>
                        {/* job cat */}
                        <tr>
                            <td colSpan={4}>
                                <JobCat></JobCat>
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.tableCell}>၁၀။</td>
                            <td className={classes.tableCell}>ကိုင်ဆောင်သည့်နိုင်ငံကူးလက်မှတ်</td>
                            <td className={classes.tableCell}>
                                NYEIN CHAN HTET
                                <hr style={{ border: '1px solid #000' }}></hr>
                            </td>
                            <td style={{ minWidth: '150px' }}></td>
                        </tr>
                        <tr>
                            <td className={classes.tableCell}>၁၁။</td>
                            <td className={classes.tableCell}>သွားရောက်မည့်နိုင်ငံ</td>
                            <td className={classes.tableCell}>
                                ဂျပန်နိုင်ငံ
                                <hr style={{ border: '1px solid #000' }}></hr>
                            </td>
                            <td style={{ minWidth: '150px' }}></td>
                        </tr>
                        <tr>
                            <td className={classes.tableCell}>၁၂။</td>
                            <td className={classes.tableCell}>အလုပ်လုပ်ကိုင်မည့်ကုမ္ပဏီအမည်</td>
                            <td className={classes.tableCell}>
                                SOE JIMA CORPORATION
                                <hr style={{ border: '1px solid #000' }}></hr>
                            </td>
                            <td style={{ minWidth: '150px' }}></td>
                        </tr>
                        <tr>
                            <td className={classes.tableCell}>၁၃။</td>
                            <td className={classes.tableCell}>အလုပ်လုပ်ကိုင်မည့်လုပ်ငန်းလိပ်စာ</td>
                            <td className={classes.tableCell}>
                                <span></span>
                                <hr style={{ border: '1px solid #000' }}></hr>
                            </td>
                            <td style={{ minWidth: '150px' }}></td>
                        </tr>
                        <tr>
                            <td className={classes.tableCell}></td>
                            <td className={classes.tableCell}></td>
                            <td className={classes.tableCell}>
                                <span></span>
                                <hr style={{ border: '1px solid #000' }}></hr>
                            </td>
                            <td style={{ minWidth: '150px' }}></td>
                        </tr>
                    </tbody>
                </table>
            </Box>
        </Box>
    );
}

export default SmartCardForm;
