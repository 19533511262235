import React from "react";
import { makeStyles } from '@mui/styles';
import { Box, Typography } from "@mui/material";

const useStyles = makeStyles({
    screen: {
        pageBreakAfter: 'always',
    },
});

const PredepCvForm = () => {
    const classes = useStyles();
    return (
        <div className={classes.screen}>
            <Box sx={{ pt: 10, px: 15 }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    position: 'relative'
                }}>
                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', lineHeight: '30px' }}>ပြည်ပသို့ သွားရောက်လုပ်ကိုင်မည့်သူများ သင်တန်းတက်ရောက်ရန် လျှောက်လွှာ</Typography>
                    <Box sx={{
                        height: '120px',
                        width: '120px',
                        border: '1px solid black',
                        position: 'absolute',
                        top: -30,
                        right: 0
                    }} />
                    </Box>
                <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', lineHeight: '30px' }}>သို့</Typography>
                <Box sx={{
                    textIndent: '50px',
                }}>
                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', lineHeight: '30px' }}>ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်း</Typography>
                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', lineHeight: '30px' }}>ရန်ကုန်မြို့</Typography>
                </Box>
                <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', lineHeight: '30px' }}>
                    အကြောင်းအရာ	။	&nbsp;	&nbsp;	&nbsp;ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်းသားအဖြစ် ဝင်ခွင့်ပြုရန် လျှောက်ထားခြင်း
                </Typography>
                <Typography sx={{
                    textIndent: '50px',
                    fontFamily: 'PdsFont',
                    fontSize: '20px',
                    pb: 2
                }}>
                    ကျွန်တော်/ကျွန်မ <span style={{
                        borderBottom: '1px dotted black',
                        padding: '0px 20px',
                        fontFamily: 'PdsFont',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        lineHeight: '40px'
                    }}>နှင်းနု</span> အား ပြည်ပသို့ သွားရောက်လုပ်ကိုင်နိုင်ရန်
                    ဌာနမှဖွင့်လှစ်သော ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်းသို့ တက်ရောက်ခွင့်ပြုပါရန် အောက်ပါ
                    အချက်များဖြင့်ဖော်ပြ၍ လျှောက်ထားအပ်ပါသည်-
                </Typography>
                    <table>
                      <tbody>
                        <tr>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px' }}>၁။</td>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px' }}>(က)</td>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', whiteSpace: 'nowrap' }}>အမည်( မြန်မာဘာသာဖြင့် )</td>
                          <td style={{ width: '500px', paddingLeft: "50px" }}>
                            <p style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', textAlign: 'center' }}>စုမြတ်</p>
                            <div style={{ paddingLeft: '50px', height: '1px', backgroundColor: '#000' }}></div>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px' }}></td>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px' }}></td>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', paddingLeft: '75px', whiteSpace: 'nowrap' }}>( အင်္ဂလိပ်ဘာသာဖြင့် )</td>
                          <td style={{ width: '500px', paddingLeft: "50px" }}>
                            <p style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', textAlign: 'center' }}>Su myat</p>
                            <div style={{ paddingLeft: '50px', height: '1px', backgroundColor: '#000' }}></div>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px' }}></td>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px' }}>(ခ)</td>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', whiteSpace: 'nowrap' }}>အသက်(မွေးသက္ကရာဇ်)</td>
                          <td style={{ width: '500px', paddingLeft: "50px" }}>
                            <p style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', textAlign: 'center' }}>Su myat</p>
                            <div style={{ paddingLeft: '50px', height: '1px', backgroundColor: '#000' }}></div>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px' }}></td>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px' }}>(ဂ)</td>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', whiteSpace: 'nowrap' }}>နိုင်ငံသားစိစစ်ရေးကဒ်ပြားအမှတ်</td>
                          <td style={{ width: '500px', paddingLeft: "50px" }}>
                            <p style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', textAlign: 'center' }}>Su myat</p>
                            <div style={{ paddingLeft: '50px', height: '1px', backgroundColor: '#000' }}></div>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px' }}></td>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px' }}>(ဃ)</td>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', whiteSpace: 'nowrap' }}>နိုင်ငံကူးလက်မှတ်အမှတ်နှင့်</td>
                          <td style={{ width: '500px', paddingLeft: "50px" }}>
                            <p style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', textAlign: 'center' }}>Su myat</p>
                            <div style={{ paddingLeft: '50px', height: '1px', backgroundColor: '#000' }}></div>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px' }}></td>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px' }}></td>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', whiteSpace: 'nowrap' }}>ထုတ်ပေးသည့်ရက်စွဲရှိလျှင်</td>
                          <td style={{ width: '500px', paddingLeft: "50px" }}>
                            <p style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', textAlign: 'center' }}>Su myat</p>
                            <div style={{ paddingLeft: '50px', height: '1px', backgroundColor: '#000' }}></div>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px' }}></td>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px' }}>(င)</td>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', whiteSpace: 'nowrap' }}>လူမျိုးနှင့်ဘာသာ</td>
                          <td style={{ width: '500px', paddingLeft: "50px" }}>
                            <p style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', textAlign: 'center' }}>Su myat</p>
                            <div style={{ paddingLeft: '50px', height: '1px', backgroundColor: '#000' }}></div>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px' }} colSpan={2}>၂။</td>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', whiteSpace: 'nowrap' }}>မိဘအမည် (မြန်မာ/အင်္ဂလိပ်)</td>
                          <td style={{ width: '500px', paddingLeft: "50px" }}>
                            <p style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', textAlign: 'center' }}>စုမြတ်</p>
                            <div style={{ paddingLeft: '50px', height: '1px', backgroundColor: '#000' }}></div>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px' }} colSpan={2}></td>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', whiteSpace: 'nowrap' }}></td>
                          <td style={{ width: '500px', paddingLeft: "50px" }}>
                            <p style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', textAlign: 'center' }}>စုမြတ်</p>
                            <div style={{ paddingLeft: '50px', height: '1px', backgroundColor: '#000' }}></div>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px' }} colSpan={2}>၃။</td>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', whiteSpace: 'nowrap' }}>မိဘနေရပ်လိပ်စာ</td>
                          <td style={{ width: '500px', paddingLeft: "50px" }}>
                            <p style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', textAlign: 'center' }}>စုမြတ်</p>
                            <div style={{ paddingLeft: '50px', height: '1px', backgroundColor: '#000' }}></div>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px' }} colSpan={2}></td>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', whiteSpace: 'nowrap' }}></td>
                          <td style={{ width: '500px', paddingLeft: "50px" }}>
                            <p style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', textAlign: 'center' }}>စုမြတ်</p>
                            <div style={{ paddingLeft: '50px', height: '1px', backgroundColor: '#000' }}></div>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px' }} colSpan={2}>၄။</td>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', whiteSpace: 'nowrap' }}>အိမ်ထောင်ရှိ/မရှိ၊ သား/သမီးရှိ/မရှိ</td>
                          <td style={{ width: '500px', paddingLeft: "50px" }}>
                            <p style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', textAlign: 'center' }}>စုမြတ်</p>
                            <div style={{ paddingLeft: '50px', height: '1px', backgroundColor: '#000' }}></div>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px' }} colSpan={2}></td>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', whiteSpace: 'nowrap' }}>ရှိလျှင် အမည်၊ အသက်နှင့် လိပ်စာ</td>
                          <td style={{ width: '500px', paddingLeft: "50px" }}>
                            <p style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', textAlign: 'center' }}>စုမြတ်</p>
                            <div style={{ paddingLeft: '50px', height: '1px', backgroundColor: '#000' }}></div>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px' }} colSpan={2}>၅။</td>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', whiteSpace: 'nowrap' }}>ပညာအရည်အချင်း</td>
                          <td style={{ width: '500px', paddingLeft: "50px" }}>
                            <p style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', textAlign: 'center' }}>စုမြတ်</p>
                            <div style={{ paddingLeft: '50px', height: '1px', backgroundColor: '#000' }}></div>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px' }} colSpan={2}>၆။</td>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', whiteSpace: 'nowrap' }}>အမြဲတမ်းနေရပ်လိပ်စာ</td>
                          <td style={{ width: '500px', paddingLeft: "50px" }}>
                            <p style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', textAlign: 'center' }}>စုမြတ်</p>
                            <div style={{ paddingLeft: '50px', height: '1px', backgroundColor: '#000' }}></div>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px' }} colSpan={2}></td>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', whiteSpace: 'nowrap' }}></td>
                          <td style={{ width: '500px', paddingLeft: "50px" }}>
                            <p style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', textAlign: 'center' }}>စုမြတ်</p>
                            <div style={{ paddingLeft: '50px', height: '1px', backgroundColor: '#000' }}></div>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px' }} colSpan={2}>၇။</td>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', whiteSpace: 'nowrap' }}>ဖုန်းနံပါတ်/Viberနံပါတ်၊ E-mail</td>
                          <td style={{ width: '500px', paddingLeft: "50px" }}>
                            <p style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', textAlign: 'center' }}>စုမြတ်</p>
                            <div style={{ paddingLeft: '50px', height: '1px', backgroundColor: '#000' }}></div>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px' }} colSpan={2}></td>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', whiteSpace: 'nowrap' }}></td>
                          <td style={{ width: '500px', paddingLeft: "50px" }}>
                            <p style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', textAlign: 'center' }}>စုမြတ်</p>
                            <div style={{ paddingLeft: '50px', height: '1px', backgroundColor: '#000' }}></div>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px' }} colSpan={2}>၈။</td>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', whiteSpace: 'nowrap' }}>သွားရောက်လိုသည့်နိုင်ငံ</td>
                          <td style={{ width: '500px', paddingLeft: "50px" }}>
                            <p style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', textAlign: 'center' }}>စုမြတ်</p>
                            <div style={{ paddingLeft: '50px', height: '1px', backgroundColor: '#000' }}></div>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px' }} colSpan={2}>၉။</td>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', whiteSpace: 'nowrap' }}>လုပ်ကိုင်ရန်ရည်ရွယ်သည့်အလုပ်အကိုင် အမျိုးအစား</td>
                          <td style={{ width: '500px', paddingLeft: "50px" }}>
                            <p style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', textAlign: 'center' }}>စုမြတ်</p>
                            <div style={{ paddingLeft: '50px', height: '1px', backgroundColor: '#000' }}></div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'end',
                    mt: 10
                }}>
                    <table>
                      <tbody>
                        <tr>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px'}}>လက်မှတ်</td>
                          <td style={{ width: '300px', paddingLeft: "50px", minHeight: '50px' }}>
                            <p style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', textAlign: 'center' }}></p>
                            <div style={{ paddingLeft: '50px', height: '1px', backgroundColor: '#000' }}></div>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px'}}>အမည်</td>
                          <td style={{ width: '300px', paddingLeft: "50px", minHeight: '50px' }}>
                            <p style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', textAlign: 'center' }}>ဖြိုးအိဇာ</p>
                            <div style={{ paddingLeft: '50px', height: '1px', backgroundColor: '#000' }}></div>
                          </td>
                        </tr><tr>
                          <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px'}}>ရက်စွဲ</td>
                          <td style={{ width: '300px', paddingLeft: "50px", minHeight: '50px' }}>
                            <p style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', textAlign: 'center' }}>၂၃-၂၅-၁၂၀၂၁</p>
                            <div style={{ paddingLeft: '50px', height: '1px', backgroundColor: '#000' }}></div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                </Box>
                <div style={{ height: '50px', width: '100%' }}></div>
            </Box>
        </div>
    )
}


export default PredepCvForm