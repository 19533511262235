import React from 'react'
import SmartCardForm from '../common/SmartCardForm'
import SmartCardForm2 from '../common/SmartCardForm2'
import NDCoverLetter from './NDCoverLetter'

const SmartCardSw = ({gpOrMem,id}) => {
  return (
    <div>
      <NDCoverLetter></NDCoverLetter>
      <SmartCardForm></SmartCardForm>
      <SmartCardForm2></SmartCardForm2>
    </div>
  )
}

export default SmartCardSw
