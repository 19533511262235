import React from 'react'
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    screen: {
        pageBreakAfter: 'always',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: '100vh',
    },
});

const MolPart2 = () => {
    const classes = useStyles();

  return (
        <Box className={classes.screen} sx={{ p: 5 }}>
            <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px' }}>-ပူးတွဲပါ</Typography>
            <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px' }}>(၁) အလုပ်ခေါ်စာ (Demand Letter) မိတ္တူ၊</Typography>
            <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px' }}>(၂) စေလွှတ်မည့် အလုပ်သမားစာရင်း၊</Typography>
            <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px' }}>(၃) အလုပ်သမားညွှန်ကြားရေးဦးစီးဌာနမှ ထုတ်ပေးသည့် အလုပ်ခေါ်စာ မှန်ကန်ကြောင်း အတည်ပြုစာ၊</Typography>
            <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px' }}>(၄) Demand Letter ပါ လူဦးရေစာရင်း</Typography>
            <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px' }}>(၅) အလုပ်သမားတစ်ဦးချင်း ဗီဇာ၊</Typography>
            <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px' }}>(၆) ပြည်ပအလုပ်အကိုင် အလုပ်သမား လမ်းညွှန်သင်တန်းဆင်းလက်မှတ်။</Typography>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                pr: 10
            }}>
                <Box sx={{
                    textAlign: 'center'
                }}>
                    <Typography sx={{
                        mt: 5,
                        mb: 15,
                        fontFamily: 'PdsFont',
                        fontSize: '20px',
                        lineHeight: '40px'
                    }}>လေးစားစွာဖြင့်</Typography>
                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px' }}>ချစ်စုဝေ</Typography>
                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px' }}>မန်နေဂျင်းဒါရိုက်တာ</Typography>
                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px' }}>Clover Mandalay Co., Ltd</Typography>
                </Box>
            </Box>
        </Box>
  )
}

export default MolPart2