import React from "react";
import { makeStyles } from '@mui/styles';
import { Box, Typography } from "@mui/material";
import letterHead from "../../../../assets/images/letter_head.png";
import waterMark from "../../../../assets/images/clover-rm-bg.png";
import MolPart2 from "./MolPart2";

const useStyles = makeStyles({
    screen: {
        pageBreakAfter: 'always',
    },
});

const MolCoverLetter = () => {
    const classes = useStyles();
    return (
        <div className={classes.screen}>
            <img src={letterHead} className="letterHead"></img>
                <Box
                    sx={{
                        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url(${waterMark})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "top",
                        backgroundSize: "60%",
                        mx: "auto",
                        px: 5,
                    }}
                >
                <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px' }}>သို့</Typography>
                <Box sx={{
                    ml: 2
                }}>
                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px' }}>ညွှန်ကြားရေးမှူးချုပ်</Typography>
                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px' }}>အလုပ်သမားညွှန်ကြားရေးဦးစီးဌာန</Typography>
                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px' }}>အလုပ်သမားဝန်ကြီးဌာန</Typography>
                </Box>
                <Typography sx={{
                    my: 2,
                    textAlign: 'end',
                    fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px'
                }}>ရက်စွဲ။ ၂၀၂၃ခုနှစ် ဇန်နဝါရီလ၁၀ရက်</Typography>
                <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px', mb: 3 }}>
                    အကြောင်းအရာ။&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;။ပြည်ပအလုပ်အကိုင်သက်သေခံကတ်ပြား (Smart Card) ထုတ်ယူခွင့်ပေးပါရန် တင်ပြခြင်း။
                </Typography>
                <Typography sx={{
                    textIndent: '50px',
                    pb: 10,
                    fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px',
                    textAlign: 'justify'
                }}>
                    အထက်ဖော်ပြပါ ပြည်ပအလုပ်အကိုင်သက်သေခံကတ်ပြား (Smart Card) ထုတ်ယူခွင့်ကိစ္စနှင့် ပတ်သက်၍ Clover Mandalay
                    Co.,Ltd ၏ ပြည်ပအလုပ်အကိုင်ရှာဖွေရေးအစီအစဉ်ဖြင့် ၂၀၂၃ခုနှစ် မေလအတွင်း ဂျပန်နိုင်ငံရှိ Jujutsu Kaisen လက်အောက်ရှိ
                    Nanami Company သို့သွားရောက် အလုပ်လုပ်မည့် အလုပ်သင်သင်တန်းသား (2)ဦးအား ပြည်ပအလုပ်အကိုင် သက်သေခံ ကတ်ပြား
                    (Smart Card) ထုတ်ယူခွင့် ပေးပါရန် လေးစားစွာ လျှောက်ထားအပ်ပါသည်။ ဂျပန်နိုင်ငံ လူဝင်မှု ကြီးကြပ်ရေး အဖွဲ့မှလည်း တရားဝင်
                    ပြည်ဝင်ခွင့်(C.O.E) ၊ ဂျပန်သံရုံးမှ ဗီဇာနှင့် ပြည်ပ အလုပ်အကိုင် လမ်းညွှန် သင်တန်းဆင်း လက်မှတ် လည်း ရရှိထားပါသည်။
                </Typography>
                    <table style={{ margin: '10px auto', width: '80%' }}>
                        <tbody>
                            <tr>
                                <td style={{ fontFamily: 'PdsFont', fontSize: '20px', padding: '20px 30px' }}>Sr</td>
                                <td style={{ fontFamily: 'PdsFont', fontSize: '20px', padding: '20px 30px' }}>Name</td>
                                <td style={{ fontFamily: 'PdsFont', fontSize: '20px', padding: '20px 30px' }}>Passport.No</td>
                            </tr>
                            {
                                personList.map((person, index) => (
                                    <tr key={index}>
                                        <td style={{ fontFamily: 'PdsFont', fontSize: '20px', padding: '10px 30px' }}>{index + 1}.</td>
                                        <td style={{ fontFamily: 'PdsFont', fontSize: '20px', padding: '10px 30px' }}>{person.name}</td>
                                        <td style={{ fontFamily: 'PdsFont', fontSize: '20px', padding: '10px 30px' }}>{person.passportNo}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </Box>
            <MolPart2></MolPart2>
        </div>
    )
}

const personList = [
    {
        name: 'HTWE HLAING',
        passportNo: '1M-111111'
    },
    {
        name: 'MYA NANDAR KHAING',
        passportNo: '1M-111111'
    }
]

export default MolCoverLetter