// import React from "react";
// import { makeStyles } from "@mui/styles";
// import { Box, Typography } from "@mui/material";

// const useStyles = makeStyles({
//   screen: {
//     pageBreakAfter: "always",
//     minHeight: "100vh",
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "center",
//   },
//   rotatedBox: {
//     width: "100%",
//     transform: `rotate(-90deg) translate(0px, -250px)`,
//     // overflowX: 'auto',
//   },
// });

// const AgencyList = ({ type }) => {
//   const classes = useStyles();
//   return (
//     <div className={classes.screen}>
//       <Box className={classes.rotatedBox}>
//         <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', textAlign: 'center', mb: 3 }}>
//           ဂျပန်နိုင်ငံသို့ နည်းပညာအလုပ်သင် သင်တန်းသားအလုပ်သမားစေလွှတ်ခွင့်
//           Demand Letter တင်ပြသည့် <br />
//           ပြည်ပအလုပ်အကိုင်အကျိုးဆောင်လိုင်စင်ရအေဂျင်စီများစာရင်း
//         </Typography>
//         <table className="custom-table">
//           <tbody>
//             <tr>
//               <td className="custom-cell-dem" rowSpan={2}>
//                 စဉ်
//               </td>
//               <td className="custom-cell-dem" rowSpan={2}>
//                 ရက်စွဲ
//               </td>
//               <td className="custom-cell-dem" rowSpan={2}>
//                 ပြည်ပအလုပ်အကိုင်အကျိုးဆောင် လိုင်စင်ရအေဂျင်စီ အမည်
//               </td>
//               <td className="custom-cell-dem" rowSpan={2}>
//                 ဂျပန်နိုင်ငံရှိ ကုမ္ပဏီအမည်
//               </td>
//               <td className="custom-cell-dem" colSpan={3}>
//                 အလုပ်သမားဦးရေ
//               </td>
//               <td className="custom-cell-dem" rowSpan={2}>
//                 အလုပ်သမားများလုပ်ကိုင်ရမည့် ဂျပန်ကုမ္ပဏီလိပ်စာ
//               </td>
//             </tr>
//             <tr>
//               <td className="custom-cell-dem">ကျား</td>
//               <td className="custom-cell-dem">မ</td>
//               <td className="custom-cell-dem">ပေါင်း</td>
//             </tr>
//             <tr>
//               <td className="custom-cell-dem">၁</td>
//               <td className="custom-cell-dem">Demand Lettter ပေးပို့ ရက်စွဲ</td>
//               <td className="custom-cell-dem" style={{ whiteSpace: 'nowrap' }}>Clover Mandalay Co., Ltd</td>
//               <td className="custom-cell-dem">
//                 Demand Letter တွင် ဖော်ပြထားသည့် -ကြီးကြပ်အဖွဲ့အစည်း အမည် -
//                 ကုမ္ပဏီ အမည်
//               </td>
//               <td className="custom-cell-dem">၁</td>
//               <td className="custom-cell-dem">၁</td>
//               <td className="custom-cell-dem">၁</td>
//               <td className="custom-cell-dem">
//                 Demand Letter တွင် ဖော်ပြထားသည့် (ကြီးကြပ်အဖွဲ့အစည်း လိပ်စာ၊
//                 Post Code၊ ဖုန်း၊ Fax No အပြည့်အစုံ) (ဂျပန်ကုမ္ပဏီ လိပ်စာ၊ ဖုန်း
//                 နံပါတ်၊ Fax No အပြည့်အစုံ)
//               </td>
//             </tr>
//           </tbody>
//         </table>
//       </Box>
//     </div>
//   );
// };

// export default AgencyList;


import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { convertToMyanmarNumbers } from "../../../../utils/enTomm";

const useStyles = makeStyles({
  screen: {
    pageBreakAfter: "always",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  rotatedBox: {
    width: "100%",
    transform: `rotate(-90deg) translate(0px, -250px)`,
    // overflowX: 'auto',
  },
  table: {
    borderCollapse: "collapse",
    width: "100%",
    lineHeight: "40px",
    marginTop: "15px",
  },
  tableHeaderCell: {
    textAlign: "center",
    verticalAlign: "middle",
    fontFamily: "PdsFont",
    fontSize: "22px",
    padding: "5px",
    border: "2px solid #000",
  },
  tableCell: {
    textAlign: "center",
    verticalAlign: "middle",
    fontFamily: "PdsFont",
    fontSize: "22px",
    padding: "10px 15px",
    border: "2px solid #000",
  },
});

const AgencyList = () => {
  const classes = useStyles();
  return (
    <div className={classes.screen}>
      <div style={{ height: "400px", width: "100%" }}></div>
      <Box className={classes.rotatedBox}>
        <Typography sx={{ fontFamily: 'PdsFontNumber', fontSize: '20px', textAlign: 'center', lineHeight: '40px', mb: 3, fontWeight: 'bold' }}>
           ဂျပန်နိုင်ငံသို့ နည်းပညာအလုပ်သင် သင်တန်းသားအလုပ်သမားစေလွှတ်ခွင့်
           Demand Letter တင်ပြသည့် <br />
           ပြည်ပအလုပ်အကိုင်အကျိုးဆောင်လိုင်စင်ရအေဂျင်စီများစာရင်း
         </Typography>
          <table className="custom-table">
           <tbody>
             <tr>
               <td className={classes.tableCell} rowSpan={2}>
                 စဉ်
               </td>
               <td className={classes.tableCell} rowSpan={2}>
                 ရက်စွဲ
               </td>
               <td className={classes.tableCell} rowSpan={2}>
                 ပြည်ပအလုပ်အကိုင်အကျိုးဆောင် လိုင်စင်ရအေဂျင်စီ အမည်
               </td>
               <td className={classes.tableCell} rowSpan={2}>
                 ဂျပန်နိုင်ငံရှိ ကုမ္ပဏီအမည်
               </td>
               <td className={classes.tableCell} colSpan={3} style={{ whiteSpace: 'nowrap' }}>
                 အလုပ်သမားဦးရေ
               </td>
               <td className={classes.tableCell} rowSpan={2}>
                 အလုပ်သမားများလုပ်ကိုင်ရမည့် ဂျပန်ကုမ္ပဏီလိပ်စာ
               </td>
             </tr>
             <tr>
               <td className={classes.tableCell}>ကျား</td>
               <td className={classes.tableCell}>မ</td>
               <td className={classes.tableCell}>ပေါင်း</td>
             </tr>
             <tr>
               <td className={classes.tableCell}>၁</td>
               <td className={classes.tableCell}>Demand Lettter ပေးပို့ ရက်စွဲ</td>
               <td className={classes.tableCell} style={{ whiteSpace: 'nowrap' }}>Clover Mandalay Co., Ltd</td>
               <td className={classes.tableCell}>
                 Demand Letter တွင် ဖော်ပြထားသည့် -ကြီးကြပ်အဖွဲ့အစည်း အမည် -
                 ကုမ္ပဏီ အမည်
               </td>
               <td className={classes.tableCell}>၁</td>
               <td className={classes.tableCell}>၁</td>
               <td className={classes.tableCell}>၁</td>
               <td className={classes.tableCell}>
                 Demand Letter တွင် ဖော်ပြထားသည့် (ကြီးကြပ်အဖွဲ့အစည်း လိပ်စာ၊
                 Post Code၊ ဖုန်း၊ Fax No အပြည့်အစုံ) (ဂျပန်ကုမ္ပဏီ လိပ်စာ၊ ဖုန်း
                 နံပါတ်၊ Fax No အပြည့်အစုံ)
               </td>
             </tr>
           </tbody>
         </table>
      </Box>
      <div style={{ height: "400px", width: "100%" }}></div>
    </div>
  );
};

export default AgencyList;
