import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { convertToMyanmarNumbers } from "../../../../utils/enTomm";

const useStyles = makeStyles({
  screen: {
    pageBreakAfter: "always",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  rotatedBox: {
    width: "100%",
    transform: `rotate(-90deg) translate(0px, -250px)`,
    // overflowX: 'auto',
  },
  table: {
    borderCollapse: "collapse",
    width: "100%",
    lineHeight: "40px",
    marginTop: "15px",
  },
  tableHeaderCell: {
    textAlign: "center",
    verticalAlign: "middle",
    fontFamily: "PdsFont",
    fontSize: "20px",
    padding: "5px",
    border: "2px solid #000",
  },
  tableCell: {
    textAlign: "center",
    verticalAlign: "middle",
    fontFamily: "PdsFont",
    fontSize: "20px",
    padding: "5px",
    border: "2px solid #000",
  },
});

const EmployeeInfoTable = () => {
  const classes = useStyles();
  return (
    <div className={classes.screen}>
      <div style={{ height: "400px", width: "100%" }}></div>
      <Box className={classes.rotatedBox}>
        <Typography
          sx={{
            textAlign: "end",
            fontFamily: "PdsFont",
            fontSize: "20px",
          }}
        >
          ပူးတွဲ - ၂
        </Typography>
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <Typography
            sx={{
              fontFamily: "PdsFont",
              fontSize: "20px",
              lineHeight: "40px",
              fontWeight: "bold",
            }}
          >
            CLOVER MANDALAY CO.,LTD
          </Typography>
          <Typography
            sx={{ fontFamily: "PdsFont", fontSize: "20px", lineHeight: "40px" }}
          >
            2024 ခုနှစ်၊ ဇန်နဝါရီလအတွင်း
          </Typography>
          <Typography
            sx={{ fontFamily: "PdsFont", fontSize: "20px", lineHeight: "40px" }}
          >
            တိုင်းဒေသကြီးနှင့် ပြည်နယ်အလိုက် ပြည်ပအလုပ်အကိုင်သမားသက်သေခံကတ်ပြား
            ထုတ်ယူသည့် <br></br> အလုပ်သမားများ၏
          </Typography>
          <Typography
            sx={{ fontFamily: "PdsFont", fontSize: "20px", lineHeight: "40px" }}
          >
            ကိုယ်ရေးအချက်အလက် အခြေပြဇယား
          </Typography>
        </Box>
        <table className={classes.table}>
          <tbody>
            <tr>
              <td className={classes.tableHeaderCell}>စဉ်</td>
              <td className={classes.tableHeaderCell}>အလုပ်သမားအမည်</td>
              <td className={classes.tableHeaderCell}>မွေးသက္ကရာဇ်</td>
              <td className={classes.tableHeaderCell}>နိုင်ငံကူး လက်မှတ်အမှတ်</td>
              <td className={classes.tableHeaderCell}>
                နိုင်ငံသားစီစစ်ရေး ကတ်ပြားအမှတ်
              </td>
              <td className={classes.tableHeaderCell}>အဘအမည်</td>
              <td className={classes.tableHeaderCell}>လူမျိုး/ ဘာသာ</td>
              <td className={classes.tableHeaderCell}>
                အမြဲတမ်းနေရပ်လိပ်စာ အပြည့်အစုံ
              </td>
            </tr>
            {personList.map((person, index) => (
              <tr key={index}>
                <td className={classes.tableCell}>
                  {convertToMyanmarNumbers(String(index + 1))}
                </td>
                <td className={classes.tableCell}>{person.workerName}</td>
                <td
                  className={classes.tableCell}
                  style={{ whiteSpace: "nowrap" }}
                >
                  {person.birthDate}
                </td>
                <td
                  className={classes.tableCell}
                  style={{ whiteSpace: "nowrap" }}
                >
                  {person.passportNo}
                </td>
                <td
                  className={classes.tableCell}
                  style={{ whiteSpace: "nowrap" }}
                >
                  {person.nrcNo}
                </td>
                <td
                  className={classes.tableCell}
                  style={{ whiteSpace: "nowrap" }}
                >
                  {person.fatherName}
                </td>
                <td className={classes.tableCell}>{person.relagion}</td>
                <td className={classes.tableCell}>{person.address}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
      <div style={{ height: "400px", width: "100%" }}></div>
    </div>
  );
};

const personList = [
  {
    workerName: "ထွေးလှိုင်",
    birthDate: "၂၆.၀၅.၁၉၉၈",
    passportNo: "1M-111111",
    nrcNo: "၁/ဟပန(နိုင်)၁၂၃၄၅၆",
    fatherName: "ဦးအောင်ငွေ",
    relagion: "ဗမာ/ဗုဒ္ဓ",
    address: "ဝန်းသိုမြို့နယ်၊ စစ်ကိုင်းတိုင်းဒေသကြီး",
  },
  {
    workerName: "မနန္ဒာခိုင်",
    birthDate: "၀၉.၀၈.၂၀၀၄",
    passportNo: "1M-111111",
    nrcNo: "၁/ဟပန(နိုင်)၁၂၃၄၅၆",
    fatherName: "ဦးထွန်း",
    relagion: "ဗမာ/ဗုဒ္ဓ",
    address: "ငါန်းဇွန်မြို့၊ မန္တလေးတိုင်းဒေသကြီး",
  },
];

export default EmployeeInfoTable;
