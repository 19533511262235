import React from 'react'
import PredepCvForm from '../common/PredepCvForm'
import MoeafCoverLetter from './MoeafCoverLetter'
import NDCoverLetter from './NDCoverLetter'
import PofAtt from './PofAtt'
import ListOfWorker from './ListOfWorker'

const PredepTitssw = ({gpOrMem,id}) => {
  return (
    <div>
      <MoeafCoverLetter></MoeafCoverLetter>
      <NDCoverLetter></NDCoverLetter>
      <ListOfWorker></ListOfWorker>
      <PofAtt></PofAtt>
      <PredepCvForm></PredepCvForm>
    </div>
  )
}

export default PredepTitssw
