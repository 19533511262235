import React from "react";
import { makeStyles } from '@mui/styles';
import { Box, Divider, Grid, Typography } from "@mui/material";

const useStyles = makeStyles({
    screen: {
        pageBreakAfter: 'always',
    },
});

const DlEng = () => {
    const classes = useStyles();
    return (
        <Box className={classes.screen} sx={{ p: 15 }}>
            <Box>
                <Box sx={{
                    textAlign: 'center',
                }}>
                    <Typography sx={{ fontFamily: 'PdsFontNumber', fontSize: '20px', fontWeight: 'bold', mb: 2 }}>International Business Consulting Association</Typography>
                    <Typography sx={{ fontFamily: 'PdsFont', lineHeight: '30px' , fontSize: '20px' }}>86-1, Asano Minami, Awaji-city, Hyogo, Japan, 656-1741 TEL: 81-(0) 799-82-3358 </Typography>
                    <Divider sx={{ my: 1 }}></Divider>
                    <Typography sx={{ fontFamily: 'PdsFont', lineHeight: '30px' , fontSize: '20px' }}>FAX: 81-(0) 799-82-3359, E-mail: info@ibca.or.jp</Typography>
                    <Divider sx={{ my: 1 }}></Divider>
                </Box>
                <Typography sx={{
                    my: 5,
                    textAlign: 'end',
                    fontFamily: 'PdsFont', lineHeight: '30px' ,
                    fontSize: '20px'
                }}>Date: 01 January 2024</Typography>
                <Typography sx={{ fontFamily: 'PdsFont', lineHeight: '30px' , fontSize: '22px'}}>CLOVER MANDALAY Co.,Ltd</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography sx={{ fontFamily: 'PdsFont', lineHeight: '30px' , fontSize: '20px'}}>Address</Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <Box sx={{
                            display: 'flex'
                        }}>
                            <Typography sx={{
                                pr: 1,
                                fontFamily: 'PdsFont', lineHeight: '30px' ,
                                fontSize: '20px'
                            }}>:</Typography>
                            <Typography sx={{ fontFamily: 'PdsFont', lineHeight: '30px' , fontSize: '20px'}}>
                                〒05011၊ No.24, Kant Kaw Street, Between 22nd x 23rd , 88th x 89th Streets, Aung Myay Thar
                                Zan Tsp, Mandalay, Myanmar
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography sx={{ fontFamily: 'PdsFont', lineHeight: '30px' , fontSize: '20px'}}>Telephone</Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <Box sx={{
                            display: 'flex'
                        }}>
                            <Typography sx={{
                                pr: 1,
                                fontFamily: 'PdsFont', lineHeight: '30px' ,
                                fontSize: '20px'
                            }}>:</Typography>
                            <Typography sx={{ fontFamily: 'PdsFont', lineHeight: '30px' , fontSize: '20px'}}>
                                +95-9-769-80-4488
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography sx={{ fontFamily: 'PdsFont', lineHeight: '30px' , fontSize: '20px'}}>Email</Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <Box sx={{
                            display: 'flex'
                        }}>
                            <Typography sx={{
                                pr: 1,
                                fontFamily: 'PdsFont', lineHeight: '30px' ,
                                fontSize: '20px'
                            }}>:</Typography>
                            <Typography sx={{ fontFamily: 'PdsFont', lineHeight: '30px' , fontSize: '20px'}}>
                                info@clovermandalay.com
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Typography sx={{
                    fontSize: '30px',
                    textAlign: 'center',
                    fontFamily: 'PdsFont', lineHeight: '30px' ,
                    my: 5
                }}>Demand Letter for Skilled Worker Recruitment</Typography>
                <Typography sx={{
                    textIndent: '50px',
                    mb: 3,
                    fontFamily: 'PdsFont', lineHeight: '30px' ,
                    fontSize: '20px'
                }}>
                    This is to confirm that we hereby appointment and authorize your agency to recruit Myanmar Specified Skilled
                    Worker/Workers has/have who completed technical intern training (2) for (Building Cleaning) to be employed in
                    our Company. You are also to have the relevant authority’s approval regarding the recruitment on behalf.
                </Typography>
                <Typography sx={{
                    mb: 3,
                    textAlign: 'center',
                    fontFamily: 'PdsFont', lineHeight: '30px' ,
                    fontSize: '20px'
                }}>
                    Our recruitment and terms of employment are as follows;
                </Typography>
                {
                    demandList.map((demandData, index) => (
                        <Grid key={index} container spacing={2} sx={{
                            pb: 3,
                            pageBreakAfter: index === 13 ? 'always' : '',
                            pt: index === 14 ? 15 : 0,
                        }}>
                            <Grid item xs={4}>
                                <Box sx={{
                                    display: 'flex'
                                }}>
                                    <Typography sx={{
                                        mr: 2,
                                        fontFamily: 'PdsFont', lineHeight: '30px' ,
                                        fontSize: '20px'
                                    }}>{index + 1}.</Typography>
                                    <Typography sx={{ fontFamily: 'PdsFont', lineHeight: '30px' , fontSize: '20px'}}>{demandData.title}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography sx={{ fontFamily: 'PdsFont', lineHeight: '30px' , fontSize: '20px'}}>: {demandData.data}</Typography>
                                <Typography sx={{ pl: 1, fontFamily: 'PdsFont', lineHeight: '30px' , fontSize: '20px' }}>{demandData.data2}</Typography>
                            </Grid>
                        </Grid>
                    ))
                }
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'end'
                }}>
                    <Box sx={{
                        textAlign: 'center',
                        pt: 10,
                    }}>
                        <Typography sx={{ fontFamily: 'PdsFont', lineHeight: '30px' , fontSize: '20px', mb: 3 }}>Yours Sincerely,</Typography>
                        <Typography sx={{ fontFamily: 'PdsFont', lineHeight: '30px' , fontSize: '20px', mb: 3 }}>Signature with seal</Typography>
                        <Typography sx={{ fontFamily: 'PdsFont', lineHeight: '30px' , fontSize: '20px', mb: 3 }}>Jujutsu Kaisen</Typography>
                        <Typography sx={{ fontFamily: 'PdsFont', lineHeight: '30px' , fontSize: '20px', mb: 3 }}>CEO 必ぎト王 Mitani Nobumasa</Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

const demandList = [
    {
        title: 'Company Name',
        data: 'Nanami Company'
    },
    {
        title: 'Company Address, Telephone, Email',
        data: 'Tokyo, Japan TEL: 0912345678'
    },
    {
        title: 'Number of workers',
        data: '1 person'
    },
    {
        title: 'Gender',
        data: 'Male'
    },
    {
        title: 'Age',
        data: '18 to 30 years old'
    },
    {
        title: 'Qualification',
        data: 'Japanese Level N4 and N5 Japanese Level'
    },
    {
        title: 'Health and Must be fit physically',
        data: 'To pass the medical examination before departure'
    },
    {
        title: 'Job category',
        data: 'Stone processing, stonework'
    },
    {
        title: 'Place of Working',
        data: 'Tokyo'
    },
    {
        title: 'Working hours',
        data: '8 hours / day'
    },
    {
        title: 'Basic Salary',
        data: '165,726 Yen/Month',
    },
    {
        title: 'Salary Payment Method',
        data: 'By Cash'
    },
    {
        title: 'Overtime',
        data: '25% of basic hourly wage'
    },
    {
        title: 'Working days',
        data: '243 days per year'
    },
    {
        title: 'Holidays/ Rest days',
        data: 'Every Sunday, Public Holiday'
    },
    {
        title: 'Accommodation',
        data: 'Arrange by the company',
        data2: 'Monthly accommodation payment by worker'
    },
    {
        title: 'Transportation',
        data: 'Bicycle commuting'
    },
    {
        title: 'Air Ticket',
        data: 'Provided by the company (1 way)'
    },
    {
        title: 'Foods',
        data: 'Arrange by the worker by themselves'
    },
    {
        title: 'Contract Period',
        data: '1 year (will be updated every day)'
    },
    {
        title: 'Health Insurance',
        data: 'Health insurance (included in Social insurance)'
    },
    {
        title: 'Other Condition',
        data: 'Follow the employment regulations of company'
    },
]

export default DlEng