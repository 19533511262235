import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { convertToMyanmarNumbers } from "../../../../utils/enTomm";

const useStyles = makeStyles({
    screen: {
        pageBreakAfter: 'always',
    },
});

const DlMm = () => {

    const classes = useStyles();

    return (
        <Box className={classes.screen} sx={{ p: 15 }}>
            <Box sx={{
                width: '90%',
                mb: 5
            }}>
                <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px' }}>ကြီးကြပ်အဖွဲ့အစည်းအမည်: International Bussiness Consulting Association</Typography>
                <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px' }}>ကြီးကြပ်အဖွဲ့အစည်းလိပ်စာ: No.86-1, Asanominami, Awaji-shi, Hyogo-ken, Japan</Typography>
                <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px' }}>ဖုန်းနံပါတ်: 0799-82-3358</Typography>
                <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px' }}>ဖက်(စ်)နံပါတ်: 0799-82-3359</Typography>
            </Box>
            <Box sx={{
                width: '80%'
            }}>
                <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px' }}>Ms. Chit Su Wai</Typography>
                <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px' }}>မန်နေးဂျင်းဒါရိုက်တာ</Typography>
                <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px' }}>Clover Mandalay Co.,Ltd.</Typography>
                <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', lineHeight: '40px' }}>
                    အမှတ် (၂၄)၊ ကံ့ကော်လမ်း၊ ၂၂ x ၂၃ လမ်းကြား၊ ၈၈ x ၈၉ လမ်းကြား၊ အောင်မြေသာစံမြို့နယ်၊ မန္တလေးမြို့ မြန်မာနိုင်ငံ
                </Typography>
            </Box>
            <Typography sx={{
                my: '10px',
                textAlign: 'end',
                fontFamily: 'PdsFont',
                fontSize: '20px'
            }}>ရက်စွဲ ဇန်နဝါရီလ၁၀ရက်၊၂၀၂၃ခုနှစ်</Typography>
            <Typography sx={{
                fontSize: '20px',
                fontFamily: 'PdsFont',
                fontWeight: 'bold',
                textAlign: 'center'
            }}>အလုပ်သင်သင်တန်းသားခေါ်ယူရန်ကမ်းလှမ်းစာ</Typography>
            <Typography sx={{
                my: 3,
                fontFamily: 'PdsFont',
                fontSize: '20px',
            }}>Dear Ms. Chit Su Wai,</Typography>
            <Typography sx={{
                mb: '10px',
                textIndent: 5,
                fontSize: '20px',
                my: 3,
                fontFamily: "PdsFont"
            }}>
                ဤကမ်းလှမ်းစာသည် ကျွန်ုပ်တို့ကုမ္ပဏီတွင် မြန်မာလူမျိုးအလုပ်သမားများကို အလုပ်ခန့်အပ်ရန်အတွက် သင်၏ အေဂျင်စီမှ အလုပ်သမားများခေါ်ယူနိုင်ရန် အပ်နှင်းသောစာဖြစ်ပါသည်။ ကျွန်ုပ်တို့၏အလုပ်သမားများခေါ်ယူခြင်းသတ်မှတ်ချက်မှာ အောက်ပါအတိုင်းဖြစ်ပါသည်။
            </Typography>
            {
                demandList.map((demandData, index) => (
                    <Grid key={index} container spacing={2} sx={{
                        pb: 3,
                        pageBreakAfter: index === 13 ? 'always' : '',
                        pt: index === 14 ? 15 : 0
                    }}>
                        <Grid item xs={index === demandList.length - 1 ? 12 : 4}>
                            <Box sx={{
                                display: 'flex'
                            }}>
                                <Typography sx={{
                                    mr: 2,
                                    fontFamily: 'PdsFont',
                                    fontSize: '20px'
                                }}>{convertToMyanmarNumbers(index + 1)}။</Typography>
                                <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>{demandData.title}</Typography>
                            </Box>
                        </Grid>
                        {
                            index === demandList.length - 1 ?
                                <></> :
                                <Grid item xs={8}>
                                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px' }}>: {demandData.data}</Typography>
                                    <Typography sx={{ pl: '10px', fontFamily: 'PdsFont', fontSize: '20px' }}>{demandData.data2}</Typography>
                                </Grid>
                        }
                    </Grid>
                ))
            }
            <Box sx={{
                textAlign: 'end',
                pt: 10
            }}>
                <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', mb: 3 }}>လေးစားစွာဖြင့်</Typography>
                <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', mb: 3 }}>International Bussiness Consulting Association (IBCA)</Typography>
                <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', mb: 3 }}>ဒါရိုက်တာကိုယ်စားလှယ် : Mitani Nobumasa</Typography>
                <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', mb: 3 }}>(လက်မှတ်) (တံဆိပ်တုံး)</Typography>
            </Box>
        </Box>
    )
}

const demandList = [
    {
        title: 'ကုမ္ပဏီအမည်',
        data: 'YABASHI DAIRISEKI Co.,Ltd'
    },
    {
        title: 'ကုမ္ပဏီလိပ်စာ',
        data: 'No.273 Akasaka-cho, Oogaki-shi, Gifu'
    },
    {
        title: 'အလုပ်သမားဦးရေ',
        data: '(၄)ဦး'
    },
    {
        title: 'ကျား/မ',
        data: 'ကျား'
    },
    {
        title: 'အသက်',
        data: 'အသက် ၂၁ မှ ၃၀ နှစ်အထိ'
    },
    {
        title: 'အရည်အချင်း',
        data: 'ဂျပန်ဘာသာစကား N4 အဆင့် ပြောဆိုတတ်ရန်'
    },
    {
        title: 'ကျန်းမာရေးနှင့် ကိုယ်ခန္ဓာအခြေအနေ',
        data: 'မြန်မာပြည်တွင်စစ်ဆေးသော ကျန်းမာရေးဆေးစစ်ချက်အောင်ရမည်။'
    },
    {
        title: 'အလုပ်အမျိုးအစား',
        data: 'ငြမ်းဆင်လုပ်ငန်း'
    },
    {
        title: 'အလုပ်နေရာ',
        data: 'No.1-16-1, Nishikinya, Hirakata-Ku, Osaka, and each worksite'
    },
    {
        title: 'အလုပ်ချိန်',
        data: 'တစ်ရက်လျှင်၇နာရီ ၀၀မိနစ်'
    },
    {
        title: 'အခြေခံလစာ',
        data: '၁၇၄,၉၃၀ ယန်း တစ်နာရီလျှင်၁,၀၇၁ယန်း',
        data2: '(၁၇၄,၉၃၀ယန်း ၁၂လ ÷ ၁၉၆၀ နာရီ )'
    },
    {
        title: 'လခပေးချေနည်း',
        data: 'ဘဏ်မှငွေလွှဲပေးခြင်း'
    },
    {
        title: 'အချိန်ပို',
        data: 'အခြေခံ ၁နာရီ လုပ်ခနှုန်း၏  အပိုဆောင်း ၂၅ ရာခိုင်နှုန်း'
    },
    {
        title: 'အလုပ်လုပ်ရက်',
        data: 'တစ်နှစ်လျှင် ၂၅၁ ရက်။'
    },
    {
        title: 'အားလပ်ရက်',
        data: 'တနင်္ဂနွေနှင့် အများပြည်သူရုံးပိတ်ရက်၊ ကုမ္ပဏီမှ သတ်မှတ်ထားသော အားလပ်ရက်'
    },
    {
        title: 'နေစရာ',
        data: 'ကုမ္ပဏီမှစီစဉ်ပေးသည်။',
        data2: 'အလုပ်သင်သင်တန်းသား၏လစဉ်လစာထဲမှ ၁၉,၂၅၀ ယန်း ပေးချေရမည်။'
    },
    {
        title: 'သွားလာစရိတ်/ လေယာဉ်လက်မှတ်',
        data: 'ကုမ္ပဏီမှကျခံမည်။'
    },
    {
        title: 'အစားအသောက်',
        data: 'အလုပ်သင်သင်တန်းသားမှစီစဉ်ရမည်၊၊'
    },
    {
        title: 'လေ့ကျင့်ရေးကာလ',
        data: '၁လ'
    },
    {
        title: 'လေ့ကျင့်ရေးကာလထောက်ပံ့ငွေ',
        data: 'ယန်း ၇၀,၀၀၀'
    },
    {
        title: 'စာချုပ်ကာလ',
        data: '၃ နှစ်'
    },
    {
        title: 'ကျန်းမာရေးအာမခံ',
        data: 'JITCO သင်တန်းသားဘက်စုံအာမခံ'
    },
    {
        title: 'ကုန်ကျစရိတ် ဝန်ဆောင်ခမှာ ဒေါ်လာ ၂၈၀၀ ဖြစ်ပါသည်။',
        data: ''
    }
]

export default DlMm